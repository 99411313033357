.container {
  // height: calc(100% - (44px));
  //background-color: #808285;
 // --background: #808285;
}

.memberArea {
  max-height: calc(100vh - 13rem);
  overflow: scroll;
}

.switcher {
  height: calc(100% - (44px + 3rem));
}
