// Base print-hiding class
:global {
  @media print {
    .hide-for-print {
      display: none !important;
      visibility: hidden !important;
    }
  }

  .birth {
    font-size: 0.8rem;
    @media print {
      font-size: inherit; // Reset to normal size in print
      display: inline;
    }
  }
}
//
//
//.print-all-content {
//  @media print {
//    height: auto !important;
//    max-height: none !important;
//    overflow: visible !important;
//    position: relative !important;
//    display: block !important;
//    page-break-before: auto !important;
//    page-break-after: auto !important;
//    margin: 0 !important;
//    padding: 0 !important;
//  }
//}


.hide-for-print {
  @media print {
    display: none !important;
  }
}

// Container class for print styling
.print-all-content {
  @media print {
    height: auto !important;
    max-height: none !important;
    overflow: visible !important;
    position: relative !important;
    display: block !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.candidatesContainer {
  @media print {
    height: auto !important;
    max-height: none !important;
    overflow: visible !important;
    position: relative !important;
    display: block !important;
    page-break-before: auto !important;
    page-break-after: auto !important;
    margin: 0 !important;
    padding: 0 !important;
  }
}

// Candidate item styling for print
.candidate-item {
  @media print {
    display: block !important;
    page-break-inside: avoid !important;
    break-inside: avoid !important;
    position: relative !important;
    margin-bottom: 30px !important;
    border: 1px solid #ccc !important;
    padding: 15px !important;
  }
}

// Force page break class
.page-break {
  @media print {
    display: block !important;
    page-break-after: always !important;
    break-after: page !important;
    height: 0 !important;
    margin: 0 !important;
    border: none !important;
  }
}

// Header group styling
.itemNavGroup {
  @media print {
    position: relative !important;
    page-break-inside: avoid !important;
    break-inside: avoid !important;
  }
}

// Loading overlay
.loadingOverlay,
.loading {
  @media print {
    position: relative !important;
    display: none !important;
  }
}

// Candidate content
.candidate {
  @media print {
    * {
      color: black !important;
      display: block !important;
      visibility: visible !important;
    }
  }
}

// Table print styles
.table-container {
  @media print {
    table {
      page-break-inside: auto !important;
    }

    tr {
      page-break-inside: avoid !important;
      page-break-after: auto !important;
    }

    thead {
      display: table-header-group;
    }

    tfoot {
      display: table-footer-group;
    }
  }
}

// Global print styles - these will be applied through composition
.print-globals {
  @media print {
    body & {
      margin: 0 !important;
      padding: 0 !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
      overflow: visible !important;
      height: auto !important;
      width: auto !important;
    }

    ::-webkit-scrollbar {
      display: none !important;
    }

    * {
      overflow: visible !important;
    }

    img {
      max-width: 100% !important;
      page-break-inside: avoid !important;
    }
  }
}
