$padding: var(--spacing);
$imageWidth: 5.5rem;
//  $imageWidthMobile: 4.5rem;

.candidate {
  margin: 0.5rem;
  padding: 0;
  width: calc(100% - 1rem);
  display: flex;
  flex-direction: column;
  font-weight: normal;
  border-radius: var(--border-radius);
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  border-bottom: 0.5rem solid var(--color-success);
  background-color: #eff8f8;

  .topSection {
    display: flex;
    flex-direction: row;
    width: 100%;

    .imageContainerJudoka, .imageContainer {
      width: 8rem;
      height: 10rem;
      margin-right:0.5rem;
      @media (max-width: 768px) {
        width: 6rem;
        height: 8rem;
      }
    }
    .imageContainer {
      flex-shrink: 0;
    }

    .personimage {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-top-left-radius: 0.3rem;
    }

    .right-side {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin: 0.5rem 0.5rem;
      &, & > *, & * {
        font-size: 0.8rem !important; // temporarily adding !important to check if it works
      }
      @media (max-width: 768px) {
        &, & > *, & * {
          font-size: 0.8rem;
        }
      }



      .info {
        margin-top: 0.5rem;
        display: flex;
        flex-direction: row;

        .select {
          flex: 1;
        }

        .buttons {
          flex: 1;

          .button {
            background-color: white;
            color: var(--primary-color);
            border: 1px solid var(--primary-color);
            margin: 0 1rem;
            padding: 0.25rem 0.5rem;

            svg {
              margin: 0.5rem;
            }
          }
        }

        .licence {
          text-align: right;
          font-weight: bold;
        }
      }
    }
  }

  &.active {
    background-color: #b6ffd9;
  }

  &.inactive {
    border-bottom: 0.5rem solid var(--color-danger);

    &.active {
      background-color: var(--color-warning);
    }
  }

  display: flex;
  flex-direction: row;
  overflow: hidden;



  .error {
    padding: 0.5rem 1rem;
    border: 2px dashed var(--primary-color);
    border-radius: 0.5rem;
    background-color: #fed6d6;
  }
}

.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;

  .name {
    flex: 1;
    width: calc(100% - 1.75rem);
    margin: 0.25rem;
  }

  .toggle {
    flex: 1;
    width: 9rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    color: var(--text-bg);
    background-color: var(--primary-color);
    border: none;
    padding: 0.5rem;
  }
}

.blankpage {
  justify-content: center;
  padding: 2rem;
  font-size: 1.2rem;

  strong {
    font-weight: bold;
    color: var(--primary-color);
  }
}

.roleName {
  text-transform: uppercase;
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
  color: var(--gray);
}

.refreshButton {
  max-width: calc(100% - 12px);
  margin: 10px 6px;
  //padding: 8px 16px;
  background-color: #f0f0f0;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background-color: #e0e0e0;
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    margin-right: 8px;
  }
}

.carddata {
  flex: 1;
  line-height: 1.5rem;
}

.assignedAkGk strong {
  font-weight: bold;
  color: blue;
  strong {
    font-weight: bold !important;
  }
}

.center {
  font-weight: bold;
  margin: 2.5rem 0;
  color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}


.statusIndicator {
  //display: flex;
  //border:2px solid red;
  gap: 4px;
  align-items: center;
  margin-left: auto; // Push to right
  flex-shrink: 0; // Prevent shrinking
  padding-left: 0.5rem;
  //min-width: 20rem !important;
}

.statusIndicatorText {
  //display: flex;
  //border:2px solid blue;
  gap: 4px;
  align-items: center;
  margin-left: auto; // Push to right
  flex-shrink: 0; // Prevent shrinking
  padding-left: 0.5rem;
}

.light {
  width: 20px;
  margin: 3px;
  height: 20px;
  border-radius: 1rem;
  border: 2px solid white;
  position: relative; // Add this for absolute positioning of status text
  &:hover {
    opacity: 0.8;
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }
}

.statusText {
  position: absolute;
  right: calc(100% + 4px); // Position text right after the dot
  white-space: nowrap; // Prevent text wrapping
  font-size: 0.9rem; // Optional: adjust text size
  line-height: 20px; // Match height of light for vertical centering
  font-weight: bold;
  .disabled {
    color: var(--gray);
    font-weight: normal !important;
  }
}

// Active states (bright)
.redActive {
  background-color: #ff0000;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.yellowActive {
  background-color: #ffbb00;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.greenActive {
  background-color: #00cc00;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

.blueActive {
  background-color: #23a5f6;
  border: 2px solid rgba(0, 0, 0, 0.3);
}

// Inactive states (dimmed)
.redInactive {
  background-color: #ffcccc;
}

.yellowInactive {
  background-color: #fff2cc;
}

.greenInactive {
  background-color: #ccffcc;
}

.blueInactive {
  background-color: #a7c1ff;
}

.spacer {
  border: 2px solid red;
  margin-left: auto;
}

.dateInput {
  margin: 0 3px;
  label {
    //
  }
  .button {
    margin-bottom:1rem;
    margin-top: 1rem;
  }
  .invitationtimeout {
    width: 10rem;
  }
}

.itemsWrapper {
  margin-bottom: 5rem;
  padding-bottom: 5rem; /* Add this */
  min-height: 1px; /* Add this */
  display: block; /* Add this */
}

.barcode {
  width: 100%;
  margin-top: 0.5rem;
  svg {
    width:auto;
    //height: 31px;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 8rem;
  @media (max-width: 768px) {
    width: 100%;
  }
  margin-right: 0.3rem;
}

.person-name {
  margin-bottom: 0.5rem;
  font-size: 0.8rem !important;
  color: blue!important;
  strong {
    font-weight: bold;
    color:red !important;
  }
}
