
.tabs {
  // margin: 0 0 env(safe-area-inset-bottom, 0) 0;
  margin-top: auto;
  padding-bottom: var(--bottom-inset);
  min-height: var(--frame-height);
  background-color: white;
  // border-top: 1px solid var(--gray-light);
  animation: loadTabs 1s ease-out;
  box-shadow: 0 0 0.25rem 0.25rem rgba(0,0,0,0.05);

  //position: absolute;
  //bottom: 0;
  //left: 0;
  //right: 0;

  .tabLayout {
    width: 100%;
    display: flex;

  }
}

@keyframes loadTabs {
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
}