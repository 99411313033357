
.layout {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background-color: white;
  background: var( --background);
  background-position: 100% 100%;
  background-repeat: no-repeat;
  background-size: 50%;
  max-width: var(--max-content-width);
  padding: 0 var(--content-padding);
  margin: 0 auto;
  border: 1px solid var(--gray-light);
}



.version{
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 0.2rem 1rem;
  font-weight: bold;
  color: rgba(255,255,255,0.8);
  backdrop-filter: blur(1rem);
  border-top-left-radius: 1rem;
  background-color: rgba(14, 144, 255, 0.3);
}
