.form {
  display: flex;
  flex-wrap: wrap;
  gap: 0;


  &.header {
    width: 100%;
    margin-top: 1rem;
    padding: var(--spacing);
    text-align: center;
    color: var(--gray-dark);
    //background-color: var(--gray-light);
    //background-color: var(--gray);
    border-bottom: 2px solid var(--gray-);
    font-weight: bold;
    font-size: 1rem;
    text-transform: uppercase;
    letter-spacing: 0.2rem;

  }
  &.content {
    border-bottom: 1px solid var(--gray-light);
    border-top: 1px solid var(--gray-light);
    border-collapse: collapse;
    font-weight: normal;
    padding: 0.2rem 0;
    &:nth-child(even) {
      background-color: var(--primary-color-super-light);
    }
    &:nth-child(odd) {
      background-color: #f2fdff;  // Gray for odd rows
    }
  }
}