.spacing {
  gap: 2rem;
}
.inventory {
  width: 20rem;
  border: 1px solid var(--gray);
  border-radius: var(--border-radius);


  .box {
    height: 30rem;
  }
}