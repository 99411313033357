$padding: var(--spacing);

$imageWidth: 6rem;

/*.background {
  // width: 100%;
  // min-height: 100%;
  //background-color: var(--gray);
  //padding: $padding $padding 5rem;
  padding: var(--spacing);
  margin-bottom: 0.5rem;
  // margin-bottom: 8rem;

  &.show-filter {
    padding-bottom: 25rem;
  }*/

.judocard {
  margin: calc(2 * var(--spacing)) var(--spacing);
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  background-color: white;
  // border: 1px solid $color_background;
  // border-bottom: 1px solid black;
  // border-bottom: 1rem solid $color_success;
  border-radius: 1rem;
  overflow: hidden;
  //border: 0.5rem solid white;
  transition: linear 150ms;
  &.active {
    background-color: var(--color-success-light);
    transform: scale(1.025);
    .card {
      border-bottom: 0.5rem solid var(--color-success);

    }
  }
  &.disabled {
    // background-color: $color_danger;
    // border-bottom: 1rem solid $color-danger;
  }

  .card {
    display: flex;
    flex-direction: row;
    //border-bottom: 0.5rem solid var(--color-success);
    border-bottom: 0.5rem solid var(--gray);
    overflow: hidden;


    .imageContainer {
      width: $imageWidth;
      height: $imageWidth;

      .image {
        display: block;
        width: $imageWidth;
        height: $imageWidth;
        object-fit: cover;
      }

      .uploadImage {
        position: absolute;
        border-radius: 100%;
        margin-top: -2.75rem;
        margin-left: 0;
      }
      .camera {
        color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(0.25rem);
        padding: var(--spacing);

      }

    }

    .content {
      width: 100%;
      .text {
        flex: 1;
        display: flex;
        width: 100%;
        //max-width: calc(100vw - calc(calc(6 * var(--spacing)) + #{$imageWidth}));
        padding: 0.5rem;
        font-size: 0.8rem;

        p {
          margin: 0;
        }

        .title {
          flex: 2;
          font-size: 1rem;
          font-weight: bold;
        }

        .subtitle {
          flex: 1;
          font-size: 0.9rem;
          font-weight: bold;
        }
      }

      .error {
        flex: 1;
        padding: 0.5rem 1rem ;
        border-top: 2px dashed var(--color-danger);
        color: var(--color-danger);
        font-weight: bold;
        //border-radius: .5rem;
        //background-color: var(--color-danger);
        //background-color: gray;
      }
    }

  }

  &.disabled {
    .card {
      // background-color: $color_danger;
      border-bottom: 0.5rem solid var(--color-danger);
      // border-bottom: 1rem solid $color-danger;
    }
  }


  .svg {
    margin-top: 0.75rem;
    width: 100%;
    background-color: var(--text-bg);

    svg {
      width: 100%;
    }
  }

}
//}

.filter-judocards {
  ion-label {
    min-width: 30%;
  }
}

.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  .name {
    flex: 1;
    width: calc(100% - 11rem);
    margin: 0.25rem;
  }

  .toggle {
    flex: 1;
    width: 9rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    //margin: 0.25rem;
    color: var(--text-bg);
    background-color: var(--primary-color);
    border: none;

    padding: 0.5rem;
  }
}

.selected {
  color: white;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 2rem;
}