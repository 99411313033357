.news {
  margin: var(--spacing);

  img {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
    margin-bottom: 1rem;

  }

  .content {

  }
}


.container {
  --background: #8c8c8c;


  .card {
    margin-top: calc(env(safe-area-inset-top) + 1rem);
    margin-bottom: calc(env(safe-area-inset-bottom) + 5rem);
  }
  &Header {
    color: black;
    font-size: 6vw;
    font-weight: bold;
  }

  &Content {
    padding: 0 1rem .5rem 1rem;
  }

  &Excerpt {
    padding: 0 1rem .5rem 1rem;
    color:black;
  }


  &Button {
    margin: 0 !important;
  }

}
