


.submitted {
  background-color: var(--color-warning);
}

.approved {
  background-color: var(--color-success-light);
}


.entry {
  display: flex;
  flex-direction: row;

  .date {
    width: 7rem;
    color: var(--text-fg);
    .org {
      margin-top: 0.25rem;
      font-weight: normal;
      font-size: 0.8rem;
      color: var(--gray);
    }
  }

  .name {
    border-left: 2px dotted var(--gray-light);
    padding-left: 1rem;
    flex: 2;
    font-weight: bold;
    display: flex;

    .judocardGroup {
        font-size: 0.85rem;
      .judocard {

        color:var(--text-bg);
        background-color: var(--color-success);
        margin: 0 0.1rem 0.1rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;

      }

    }
    .info {
      flex: 1;
      margin: 0 0.5rem;
      .amount {
        padding: 0.25rem 0.5rem;
        text-align: right;
        border-radius: 0.25rem;

      }
      .kyupruefung {
        text-align: center;
        margin-top: 0.3rem;
        color: var(--gray);
        font-size: 0.85rem;
      }
      .status {
        font-size: 0.85rem;
        padding: 0.25rem 0.5rem;
        text-align: center;
        background-color: var(--gray-super-light);
        border-radius: 0.25rem;
      }
    }
  }
}