.tournament {
  height: var(--content-height);
  overflow: hidden;

//  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .info {
    padding: var(--spacing);
    //height: calc(calc(0.5 * var(--content-height)) - calc(var(--spacing) * 2));
    height: 100%;

    overflow-x: scroll;
    h2 {
      margin: var(--spacing) 0;
      font-size: 1.25rem;
      word-break: break-word;
      padding-right:2rem;

    }

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: var(--gray);
      text-transform: uppercase;
      margin: 0;
    }

    .head {
      display: flex;
      flex-direction: row;
      .title {
        flex: 1;
      }

      .date {
        border: 1px solid var(--gray-light);
        .month {
          padding: var(--spacing);
          background-color: var(--gray-light);
          text-align: center;
          text-transform: uppercase;
          color: white;
        }

        .day {
          padding: 0 var(--spacing);
          color: var(--primary-color);
          font-size: 1.8rem;
          text-align: center;
        }

        .weekday {
          padding: 0 var(--spacing);
          text-align: center;
          color: var(--gray);
          font-size: 0.8rem;
        }
      }
    }
    hr {
      border-color: var(--gray-light);
      border-style: solid;
    }
  }

  .map {
    width: 100vw;
    position: relative;

    min-height: calc(0.5 * var(--content-height));
//    height: calc(50vh - 5.5rem);
    //position: absolute;
    //bottom:0;
    //left:0;
    //right:0;

    overflow: hidden;

    .address {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1000;
      backdrop-filter: blur(0.5rem);
      padding: var(--spacing);
    }
  }

  .item {
    margin-top: var(--spacing);
    display: flex;
    flex-direction: row;
    width: 100%;

    .share {
      flex: 1;
      justify-content: right;
      text-align: right;
      color: var(--primary-color);
      background-color: white;
      padding: 0 var(--spacing);

    }
  }

}

