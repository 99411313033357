.button {
  color: white;
  background-color: var(--primary-color);
  width: 100%;
  margin: 0.5rem 0;
  padding: 0.5rem;
  border: none;

  font-size: 1.2rem;
  text-transform: uppercase;
  border-radius: var(--border-radius);

  &.disabled {
    background-color: var(--gray);
  }
}