.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  min-width: 350px;
  min-height: 450px;

  canvas {
    width: 100%;
    height: 100%;
    min-width: 350px;
    min-height: 450px;
  }
}