
.judges {
  padding: 0.5rem;
  width: 100%;
  .header {
    padding: 0.5rem;
    font-weight: bold;
    display: flex;
    .text {
      flex: 1;
      display:block;
      overflow: hidden;
    }
    .judoCard {

      background-color: var(--color-success);
      color: var(--text-bg);
      padding: 0.25rem;
      margin: -0.25rem 0.25rem 0;
    }
  }

  .content {
    padding: 0.5rem;
    font-weight: normal;
    color: var(--text-fg);
    color:#12a2ff;
  }

  .age {
    font-weight: bold;
    color: var(--text-fg)
  }

}

.save {
  width: calc(100% - 0.6rem);
  margin: 0rem 0.3rem 2rem 0.3rem;
}


.delete {
  margin-left: 1rem;
  margin-top: 0.2rem;
  padding: 0.2rem 0.5rem !important;
  height: 0.3rem !important;
  background-color: var(--primary-color) !important;
  &:hover {
    background-color: black !important;
    color: var(--color-danger) !important;
  }
}

.uploader {
  width: 100%;
  border:0.5rem solid var(--color-info) !important;
  margin:0.5rem;
  padding:1rem;

  & button {
    background-color: var(--color-info) !important;
  }
}

.select {
  display: flex;
  .dropdown {
    flex: 1
  }

  .clear {

    margin-left:0.5rem;
    font-size: 1.2rem;
  }
}

.missingAk {
  color: var(--primary-color);
  font-weight: bold;
}

.anmeldung {
  margin-bottom: 15rem;
}

ausschreibung, li {
  line-height: 2rem;
  a {
    margin-left: 0.5rem;
  }
}

.anmeldungHinweis {
  margin-bottom: 8rem;
  color: var(--primary-color) !important;
}

.children {
    font-weight: bold;
    color:var(--primary-color);
}

ul {
  list-style-type: none;
  padding-left:0.2rem;
  li {
    font-weight: bold !important;
    color: var(--gray-dark);

  }
}

.info {
  color: var(--primary-color);
}

.headerControls {
  display: flex;
  justify-content: flex-end;
  //gap: 0.1rem;
  margin-bottom: 1rem;
  // By default (desktop), buttons are side by side
  flex-direction: row;

  .button, .deletebutton {
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    cursor: pointer;
    transition: background-color 0.2s;
    background-color: var(--primary-button);
    &:hover {
      background-color: var(--primary-button-hover);
      border: 0px!important;
    }
    .export {
      font-size: 1.2rem;
    }
  }

  .deletebutton:hover {
      background-color: red;
  }

  // On mobile screens (you can adjust the breakpoint as needed)
  @media (max-width: 768px) {
    flex-direction: column; // Stack buttons vertically
    width: 100%; // Take full width on mobile
    gap: 1rem;
    // If you want the buttons to take full width on mobile
    .button {
      width: calc(100% - 3rem);
    }
  }
}

.itemnavgroup{
  margin-bottom: 10rem;
}

