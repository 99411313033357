

.container {
  border-bottom: 0.5rem solid var(--gray);
  width: 100%;
  display: flex;
  .list {
    width: 100%;
  }
  .datev {
    text-transform: uppercase;
    // min-width: 4rem;
    padding: 0.5rem;
    font-size: 1.5rem;
    color: white;
    font-weight: bold;
    letter-spacing: 0.2rem;
    background-color: var(--gray-light);
    writing-mode: vertical-lr;
    text-orientation: upright;
    text-align: center;
  }

  .date {
    // width: 3rem;
    padding-right: 0.5rem;
    min-width: 3rem;

    height: 3rem;
    border-right: 1px dashed var(--gray);
    color: var(--gray);
    font-size: 1.8rem;
    text-align: center;
  }

  .state {
    // width: calc(100% - 0.5rem);
    font-size: 0.8rem;
    font-weight: normal;
    position: relative;
    text-align: center;
    margin: 0;
    border-radius: 2px;

    &.closed {
      color: var(--gray);
      border: 1px solid var(--gray-light);
      //background-color: #eee;
    }

    &.soon {
      color: white;
      //border: 1px solid var(--gray-light);
      background-color: #39a7fc;
    }
    &.ending {
       color: white;
       //border: 1px solid var(--gray-light);
       background-color: #f56565;
     }

    &.open {
      color: white;
      background-color: #28ba62;
      font-size: 0.7rem;
    }

    &.angem {
      color: white;
      background-color: darkblue;
      font-size: 0.7rem;
    }
    &.wait {
      color: var(--gray);
      border: 1px solid var(--gray-light);
      //background-color: #eee;
    }

    &.openBundesweit {
      color: green;
      background-color: var(--gray-light);
      font-size: 0.7rem;
    }
  }

  .title {
    flex: 1;
    padding-left: 1rem;
    font-weight: bold;
  }
  .meetingTypeName {
      color: var(--light-blue);
  }
  .meetingEnrolled {
    .meetingTypeName {
      color: darkblue;
      //&::after {
      //  content: " [angemeldet]";
      //  color:red
      //}
    }
    background-color: lightgreen;
  }


  .arrow {
    width: 2rem;
    margin-top: 1rem;
    color: var(--gray-light)
  }
  .item {
    display: flex;
    flex-direction: row;
  }
  .enrolmentdate {
    font-weight: normal;
    color: var(--gray);
  }
}

/************************/

.events-content {


  .competitions-list {

    ion-item {
      cursor: pointer;
      display: block;
//      --background: #{$color_background};
  //    border-bottom: 1px solid #{$color_background};
      margin-left: -0.5rem;

      h2 {
        color: black;
        font-size: 0.9rem;
        font-weight: normal;
        margin-left: 0.3rem;
      }

      .date {
        font-size: 0.7rem;
      }

      .open-status {
        font-size: 0.7rem;
        max-width: 4em;
      }

      .title {
        flex: 2 2 0;
        font-weight: bold;
      }
    }

    div {
      padding: 0.2rem;
    }
  }

  .subHeader {
    font-size: 1rem !important;
  }

  h1.header {
    font-weight: bold !important;
    font-size: 1.2rem !important;
    text-align: center !important;
    color: white !important;
    // background-color: #{$color_primary};
    padding: 1rem;
  }



  .from-date {
    color: black;
    font-size: 0.8rem;
    font-weight: normal;
  }

  .file-name {
    font-weight: normal !important;
    color: blue !important;
    min-width: 100% !important;
    max-width: 100% !important;
  }

  .competition-map {
    margin-top: 1rem;
    width: 100%;
    height: 15rem;
  }

  .subscription {
    border: 1px solid #ffffff;
    border-radius: 5px;
  }

  .subscription:hover {
    border: 1px solid #ffffff;
    border-radius: 5px;
    opacity: 0.8;
  }

  .trash:hover {
    opacity: 0.8;
    color: white;
  }

  .trash {
    opacity: 0.8;
    color: white;
  }

  .left-label, .ion-text-wrap {
    font-size: 0.8rem !important;
  }

  .left-label {
    max-width: 8em;
  }

  .in-list {
    padding-left: 0px;
  }

  div.item-native {
    padding-left: -0.5em;
  }

  .auschreibung {
    font-size: 1rem;
    color: #999999;
    margin: 1rem 1.5rem 0.2rem;
  }
}