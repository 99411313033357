
.navItem {
  margin: 0;
  padding: 0;



  .item {
    display: flex;
    flex-direction: row;

    .avatar {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
      image {
        width: 4rem;
        height: 4rem;
      }
    }

    .text {
      margin-left: 1rem;
      flex: 1;
      .bottom {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        font-weight: normal;
        .birthdate {
          flex: 1;
          color: var(--gray);
          text-align: right;
        }
        .org {
          flex: 1;
          color: var(--gray)
        }
      }


    }
  }
}