.container {
  flex: 1;
}

.file {
  max-width: 0;
  max-height: 0;
  margin: 0;
  padding:0;
  display: block;
}

.camera {
  flex: 1;
  min-width: 100%;
  max-width: 100%;
}