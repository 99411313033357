.imageContainer {
  position: relative;
  display: inline-block;

  &:hover .downloadButton {
    opacity: 1;
  }
}

.image {
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  max-width: 100%;
  height: auto;

  &.active {
    opacity: 1;
  }
}

.downloadButton {
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  padding: 4px 8px;
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    background-color: rgba(255, 255, 255, 1);
  }
}

.fileContainer {
  display: flex;
  align-items: center;
  padding: 12px;
  background-color: #f3f4f6;
  border-radius: 8px;
  transition: background-color 0.2s ease-in-out;

  &:hover {
    background-color: #e5e7eb;
  }
}

.fileIcon {
  font-size: 1.5rem;
  margin-right: 8px;
}

.fileName {
  font-size: 0.875rem;
  color: #374151;
  word-break: break-all;
}

.fileLink {
  text-decoration: none;
  display: block;

  &:hover {
    text-decoration: none;
  }
}

.errorContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f3f4f6;
  color: #666;
  padding: 1rem;
  border-radius: 0.5rem;
  font-size: 0.875rem;
}