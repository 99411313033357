
.loading {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 10;
  //backdrop-filter: blur(0.25rem);
  background-color: rgba(255,255,255,0.75);
  animation: fadeInAnimation 100ms ease-in;
}
@keyframes fadeInAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
.candidate {
  margin: var(--spacing);
  padding: var(--spacing);
  width: calc(100% - calc(4 * var(--spacing)));
  font-weight: normal;
  border-radius: 0.5rem;
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  border-bottom: 0.5rem solid var(--color-success);
  &.active {
    background-color: var(--color-success-light);
    //color: white;
  }
  &.inactive {
    border-bottom: 0.5rem solid var(--color-danger);
    &.active {
      background-color: var(--color-warning);
    }
  }


  .header {
    font-weight: bold;
    font-size: 1.2rem;
  }

  .info {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;

    .name {
      flex: 1;
    }

    .select {
      flex: 1;
    }

    .belt {
      flex: 1;
      margin-top: 0.5rem;
      svg {
        width: 100%;
        background-color: var(--gray-light);
        height: auto;
        box-shadow: 0 0 0 0.1rem var(--gray-light);

        //border: 1px solid var(--gray-light)
      }
    }
  }
  .licence {
    text-align: right;
    font-weight: bold;
  }

  .error {
    padding: 0.5rem 1rem ;
    border: 2px dashed var(--primary-color);
    border-radius: .5rem;
    background-color: #fed6d6;
    //background-color: gray;
  }
}

.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  .name {
    flex: 1;
    width: calc(100% - 1.75rem);
    margin: 0.25rem;
  }

  .toggle {
    flex: 1;
    width: 9rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    //margin: 0.25rem;
    color: var(--text-bg);
    background-color: var(--primary-color);
    border: none;

    padding: 0.5rem;
  }
}