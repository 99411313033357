
.active {
  //background-color: var(--color-success-light);
  width: 0.75rem;
  height: 0.75rem;
  margin: 0.25rem 1rem  0.25rem 0.25rem;
  border-radius: 100%;
  background-color:  var(--color-success-light);
}

.add {
  background-color: var(--color-success-light);
  transition: filter 0.2s ease;
  &:hover {
    filter: brightness(0.92);

  }
}

.item{
  &:hover {
    background-color: var(--gray-super-light);
  }
}
.entry {
  display: flex;
  flex-direction: row;
  padding-right: 1rem;

  .date {
    width: 6rem;
    color: var(--gray-dark);
  }

  .name, .kyu_id {
    //border-left: 1px solid var(--gray-dark);
    padding: 0rem 0.1rem;
    flex: 2;
    font-size: 1rem;
    color: var(--gray-dark);
    text-align: center;
    //&::before {
    //  content: 'Nr. ';
    //}
  }

  .teilnehmer {
    //border-left: 1px solid var(--gray-dark);
    padding: 0rem 0.2rem;
    text-align: right;
    flex: 2;
    flex-grow: 0;
    min-width: 3rem;
    //font-weight: bold;
    color: var(--gray-dark);
  }
}

.status {
  flex: 1;
  float: right;
  margin-right: 1rem;
  margin-top: -0.5rem;
  padding: 0.25rem 0.5rem;
  color: white;
  border-radius: var(--border-radius);
  font-size: 0.7rem;

  &.erstellt {
    background-color: dodgerblue;
  }

  &.abgeschlossen {
    background-color: var(--color-success);
  }

  &.freigegeben_alt {
    background-color: white;
    border: 1px solid var(--gray-dark);
    color: var(--gray-dark);
  }

  &.beantragt {
    color: var(--text-fg);
    background-color: var(--color-warning);
  }

  &.freigegeben {
    background-color: var(--gray-light);
    color: var(--gray-dark);
  }
}

.org {
  float: left;
  padding-top: 0.5rem;
}

.vorsitz {
  float: left;
  color:blue;
  display: block;
  clear: both;
  font-size: 0.8rem;
  margin-top: 0.5rem;
}
.editor {
  float: left;
  color:var(--gray);
  display: block;
  clear: both;
  font-size: 0.8rem;
}

.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  margin: 1rem;
}

.searchInput {

  &:focus {
    //outline: none;
    border-color: rgba(var(--primary-color-rgb), 0.3);
    background-color: rgba(var(--primary-color-rgb), 0.05);
    //box-shadow: 0 0 0 2px rgba(var(--primary-color-rgb), 0.1);
  }

  &::placeholder {
    color: var(--gray);
  }
}


.dateFilter {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 0.25rem;
  border: 1px solid var(--gray-light);
}

.dateInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.dateInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-weight: bold;
    color: var(--gray-dark);
  }

  .input {
    width: 100%;
  }
}

.filterheader {
  font-weight: bold;
  text-align: center;
  color: var(--gray) !important;
  margin-bottom: 0.5rem;
}
