.card {
  display: block;
  margin: var(--spacing) auto;
  max-width: 40rem;
  background-color: var(--white);
  //border: 1px solid var(--gray-light);
  box-shadow: var(--shadow);
  border-radius: var(--border-radius);
  overflow: hidden;

  &.padding {
    padding: var(--spacing) calc(2 * var(--spacing));
  }

  h1 {
    font-size: 1.2rem;
  }
}