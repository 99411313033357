.duplicate {
  display: flex;
  flex-direction: row;
  div {
    flex:1;
    padding: 0.5rem;
  }
  .duplicateImage {
    width: 3rem;
    height: 4rem;
    object-fit: cover;

  }
 }

.selected {
  background-color: var(--color-success-light);
}

.disabled {
  color: var(--gray);
}

.someContainer {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin:0;
  .someRow {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    div {
      padding: 0;
      margin: 0;
      flex: 1;
    }
  }

}