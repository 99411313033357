.pin {
  display: flex;
  margin-top: calc(0.5 * var(--spacing));
  margin-bottom: calc(0.5 * var(--spacing));

  label {
    padding: var(--spacing) 0;
    flex: 1;
  }
  input {
    font-size: 1.25rem;
    flex: 2;
    max-width: 66%;


    border: 1px solid var(--gray-light);
    padding: calc(0.5 * var(--spacing));
    border-radius: var(--border-radius);
  }
}