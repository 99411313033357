.email {
  display: flex;
  margin-top: calc(0.5 * var(--spacing));
  margin-bottom: calc(0.5 * var(--spacing));
  width: 100%;
  label {
    padding: 0.5rem 0;
    flex: 1;
  }
  input {
    background-color: var(--primary-color-super-light);
    font-size: 1.25rem;

    //max-width: 66%;
    flex: 2;
    border: 1px solid var(--gray);
    padding: var(--spacing);
    border-radius: var(--border-radius);

    &:active {
      background-color: white;
    }
  }
}