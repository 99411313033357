.header {
  display: flex;
  padding-top: var(--top-inset);
  min-height: var(--frame-height);
  background-color: var(--primary-color);
  background: radial-gradient(circle at 25% 25%, rgba(var(--primary-color-rgb), 0.95) 0,  rgba(var(--primary-color-dark-rgb), 0.9) 100%);
  backdrop-filter: blur(1rem);
  box-shadow: 0 0 0.25rem 0.25rem rgba(0,0,0,0.05);

  h1 {
    text-transform: uppercase;
    color: white;
    font-size: 1.5rem;

    // margin: calc(3 *  var(--spacing)) 0 0 calc(2 * var(--spacing));
    margin: 1.5rem 0 0;
    text-align: center;
    vertical-align: middle;
    display: inline-block;
    width: 100%;
  }

  .menu {
    position: absolute;
    height: var(--frame-height);
    display: flex;

  }

}

.filter {
  font-size: 1.2rem;
  border-bottom: 2px solid var(--gray-light);
  @media print {
    display: none !important;
  }
  &Text {
    padding: var(--spacing) calc(2 * var(--spacing));
  }

  &Icon {
    color: var(--primary-color);
    float: right;
    padding: 0.75rem 1rem;
  }
}

.content {
  border-top: 2px solid var(--gray-light);
  padding: calc(2 * var(--spacing));
}


@keyframes loadHeader {
  from {
    transform: translateY(-100%);
    background: radial-gradient(circle at top right, rgba(var(--primary-color-rgb), 0.95) 0,  rgba(var(--primary-color-dark-rgb), 0.9) 100%);
  }
  to {
    transform: translateY(0);
    background: radial-gradient(circle at 25% 25%, rgba(var(--primary-color-rgb), 0.95) 0,  rgba(var(--primary-color-dark-rgb), 0.9) 100%);
  }
}