.search {
  //padding: 1rem;
  margin: 0.5rem;
  .name {
    width: calc(100% - 2rem);
  }
}

.content {
  .entry {
    display: grid;
    grid-template-columns: 1fr; // 1fr 4fr 1/4 and 3/4 split
    gap: 0.2rem;
    width: calc(100% - 0.5rem);
    padding: 0.5rem;

    .notificationId {
      font-size: 0.875rem;
      color: #666;
      white-space: nowrap;
    }

    .name {
      .types {
        display: flex;
        gap: 4px;
        margin-top: 4px;
        flex-wrap: wrap;
      }

      .type {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
        border-radius: 0.3rem;
        background-color: #e0e7ff;
        color: #4338ca;
        margin-bottom: 0.5rem;
      }
    }

    .date {
      font-size: 0.875rem;
      color: #666;
      white-space: nowrap;
    }

    .id {
      font-size: 0.875rem;
      color: #666;
      white-space: nowrap;
    }
  }

  &:hover {
    background-color: #f3f4f6;
  }
}
