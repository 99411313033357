.container {
  display: flex;
  position: relative;
  //min-height: 200px;
  // height: 30vw;
  justify-content: space-between;
  // margin: 1rem;

  &.new {
    /*-webkit-box-shadow: 0 0 0 0.25rem rgb(184, 32, 37) !important;
    -moz-box-shadow: 0 0 0 0.25rem rgb(184, 32, 37) !important;
    box-shadow: 0 0 0 0.25rem rgb(184, 32, 37) !important;*/
    border: 4px solid var(--primary-color);


    &Badge {
      position: absolute;
      right: 4px;
      top: 4px;
    }

  }

  &Mask {
    width: 40% !important;
    height: 100% !important;
    position: absolute;

    right: 0;
    top: 0;
    //position: absolute;
    //width: 100%;
    //height: 100%;

    //top: 0;
    //left: 0;

    user-select: none;

    // background: rgb(255,255,255);
    //background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 60%, rgba(255,255,255,0) 100%);
    mask-image: linear-gradient(115deg, rgba(255,255,255,0) 25%, rgba(255,255,255,0.6) 60%, rgba(255,255,255,.6) 100%);
  }

  &Title {
    font-size: 1.2rem;
    width: 100%;
  }

  &Abstract {
    text-wrap: normal;
    word-wrap: break-word;
    font-size: 0.8rem;
  }

  &Content {
    //display: flex;
    // flex-direction: column;
    width: 100%;
    // justify-content: space-between;
    padding: 1rem;
    // position: relative;
    z-index: 1;
  }

  &Image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  p {
    margin-bottom: 0;
  }
}


//@media screen and (max-width: 400px){
//  .container {
//    min-height: 3rem;
//  }
//}
