.app {

}
.center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
  margin: auto;
  margin-top: 10vh;
  padding:1rem;

  .version {
    display: flex;
    padding: 1rem;
    font-size: 1vh;
    font-weight: bold;
    font-weight: bold;
    color: var(--gray);
    justify-content: center;
    align-items: center;
  }

  .image {
    background-image: var(--logo);
    // "../../theme/images/logo.png"
    background-position: center;
    background-size: 25rem;
    background-repeat: no-repeat;
    width: 100%;
    height: 25vh;
//    border:1px solid black;
  }

  .item {

  }

  .select {
    width: 36rem !important;
    border:1px solid var(--gray-dark) !important;
    border-radius: 0.3rem !important;
  }

  .helplink {
    margin-top: 1rem;
    border:1px solid var(--gray-dark) !important;
  }
}