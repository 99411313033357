$padding: var(--spacing);
$imageWidth: 6.5rem;

.examList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.3rem;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.examItem {
  display: flex;
  gap: 0.5rem;
  padding: 0.5rem;
  border-bottom: 2px solid var(--gray-light);
  border-radius: 0.25rem;
  background-color: var(--background-color);

  .examLabel {
    font-weight: normal;
    color: black;
    max-width: 15rem;
  }

  .examValue {
    flex: 1;
    color: blue;
  }
}

.candidate {
  margin: var(--spacing);
  //padding: var(--spacing);
  width: calc(100% - calc(4 * var(--spacing)));
  font-weight: normal;
  border-radius: 0.25rem;
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  border-bottom: 0.5rem solid var(--color-success);
  background-color: white;

  &.active {
    background-color: #e1efff;
    //color: white;
  }

  &.inactive {
    background-color: white;
  }

  &.nojudocard {
    border-bottom: 0.5rem solid var(--color-danger);
  }

  //&.regelverletzung {
  //  border-bottom: 0.5rem solid var(--color-danger);
  //  background-color: var(--gray-light);
  //}
  &.regelverletzung {
    border-bottom: 0.5rem solid var(--color-danger);
    background-color: #fff5f5;

    //&::before {
    //  content: '';
    //  position: absolute;
    //  top: -50%; // Start above the container
    //  left: 0;
    //  width: 200%; // Make it wide enough to cover the diagonal
    //  height: 1px; // Line thickness
    //  background-color: red;
    //  // transform: rotate(45deg);
    //  transform-origin: 0 0;
    //  pointer-events: none;
    //  z-index: 1;
    //}
  }
}

  .card, .info, .error {
    position: relative;
    z-index: 2;
  }

  .info {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;

    .name {
      flex: 1;
    }

    .select {
      flex: 1;
    }

  }
  .licence {
    text-align: left;
    font-weight: bold;
    color: var(--gray-dark);
    padding: 1px;

    span {
      margin: 0 0.2rem;
      padding: 0 0.3rem;
      border-radius: 0.2rem;
      &.valid {
        background-color: var(--color-success);
        color:white
      }

      &.missing {
        background-color: transparent;
        color:orangered;
      }
    }
  }

  .error {
    padding: 0.5rem 1rem ;
    border: 2px dashed var(--primary-color);
    border-radius: .5rem;
    background-color: #fed6d6;
    //background-color: gray;
  }


.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;


  .toggle {
    flex: 1;
    width: 9rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    //margin: 0.25rem;
    color: var(--text-bg);
    background-color: var(--primary-color);
    border: none;

    padding: 0.5rem;
  }
}

.filter {
  width: 100%;
}



.kyuUrkunden {
  cursor: pointer;
  font-weight: bold;
  font-size: 1.1rem;
  margin-left: -0.5rem;
  text-decoration: none;
  padding:0.5rem;
  color: var(--gray-dark);
  &:hover{
    text-decoration: none;
    color: var(--primary-color);
  }
  &.all {
    font-size: 1.3rem;
    display: block;
    text-align: center;
    margin-left: 10rem;
    margin-right: 10rem;
  }
}

.card {
  display: flex;
  flex-direction: row;
  overflow: hidden;
}

.imageContainer {
  width: $imageWidth;
  height: $imageWidth;
  flex: 0 0 auto;
  margin-right: 0.5rem;

  .image {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: 0.3rem;
    border: 1px solid var(--gray-light);
  }
}

.rightSide {
  display: flex;
  flex-direction: column;
  flex: 1;
  min-width: 200px;
}

.header {
  font-weight: bold;
  font-size: 1rem;
  margin: 0.5rem;
  width: 100%;
  background-color: var(--background-color);

  strong {
    display: block;
    margin-bottom: 0.25rem;
  }

  div {
    font-size: 1rem;
    color: var(--text-color-secondary);
  }
}

.lastexam {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  padding-left: 0.5rem;
}

.children {
  padding-left: 1rem !important;
  color: var(--primary-color);
  font-weight: bold;

  &.editor {
    font-size:1rem;
    color: var(--gray-dark);
  }
}

.editButton {
  margin: 2rem auto;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  max-width:99%;
  border: 0px!important;
  background-color: var(--primary-color) !important;
  &:hover {
    background-color: darkred !important;
    border: 0px!important;
  }

  &:disabled {
    background-color: var(--gray-dark) !important;
  }
}




.deleteButton {

  &:hover {
    background-color: red !important;

  }

  &:disabled {
    background-color: var(--gray) !important;
  }
}

.approveButton {
  &:hover {
    background-color: green !important;
  }

  &:disabled {
    background-color: var(--gray) !important;
    color:white;
  }
}

//.saveButton {
//  &:hover {
//    background-color: darkgreen !important;
//    border: 0px!important;
//  }
//
//  &:disabled {
//    background-color: var(--gray) !important;
//    color:white;
//  }
//}
.hint {
  color: var(--primary-color);
  font-weight: bold;
  padding: 0.5rem;
  text-align: center;
}

.candidatesContainer {
  position: relative;
  min-height: 200px; // Adjust this value as needed
  margin-bottom: 10rem;
}

.loadingWrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100000; // Ensure it's above other content

  :global(.ring) {
    transform: translateY(-80px); // Adjust this value to shift the ring up
  }
}
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 100000;
}

.filterheader {
  font-weight: bold;
  text-align: center;
  color: var(--gray) !important;
}
.licenceLinkBg {
  margin: 1rem 0;
  .licenceLink {
    //display: block;
    margin: 1rem 0;
    padding: 0.5rem 0.7rem;
    border-radius: 0.2rem;
    background-color: var(--kyu);
    border: 1px solid var(--gary-light);
    text-decoration: none;
    font-weight: normal;
    color: white !important;

    &:hover {
      background-color: color-mix(in srgb, var(--kyu), black 20%) !important;
    }
  }
}

.center {
  font-weight: bold;
  margin: 2.5rem 0;
  color: var(--gray);
  display: flex;
  justify-content: center;
  align-items: center;
}

.statisticsContainer {
  padding: 0.1rem;
}

.statisticsGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  gap: 1rem;
  margin-bottom: 1rem;
}

.statisticsItem {
  background-color: var(--background-color);
  padding: 0.5rem;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 0.5rem;  // Add small gap between color squares and statistics
}

.beltStatistic {
  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  strong {
    font-size: 1.1em;
  }
}

.beltCount {
  color: var(--text-color-secondary);
}

.beltGrade {
  font-size: 0.9em;
  color: var(--text-color-tertiary);
}

.statisticsTotal {
  margin-top: 0.1rem;
  margin-right: 1rem;
  text-align: left;
}

.scrollToTopButton {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
  opacity: 0;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: all;
  }

  button {
    width: 48px !important;
    height: 48px !important;
    padding: 0 !important;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}

@media print {
  .scrollToTopButton {
    display: none;
  }
}

.candidateContainer {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.mainContent {
  flex: 1;
}

.card {
  display: flex;
  gap: 1rem;
  width: 100%;
}

.info {
  min-width: 240px;

  @media (min-width: 768px) {
    margin-left: 1rem;
  }
}

.belt {
  display: flex;
  align-items: center;
  gap:1rem;
  margin: 0.5rem;
  min-width: 200px;
  max-width: 200px;

  .belt-info {
    display: flex;
    align-items: center;
  }
}

.belt-color {
  min-width: 200px !important;
  max-width: 200px !important;
}