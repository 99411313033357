//.layout {
//  display: flex;
//  flex-direction: column;
//  height: 100vh;
//  width: 100vw;
//}
.layout {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  position: fixed;  // Add this
  top: 0;          // Add this
  left: 0;         // Add this
}

.contentContainer {
  flex: 1;
  overflow-y: auto;
  padding-bottom: 65px; // Height of tabs
}

.tabsContainer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 65px;  // Fixed height for tabs
  background: white;
  z-index: 1000;

  // iOS specific fixes
  @supports (-webkit-touch-callout: none) {
    padding-bottom: env(safe-area-inset-bottom);
    height: calc(60px + env(safe-area-inset-bottom));
  }

  // Add box shadow for better visibility
  box-shadow: 0 -2px 15px rgba(0, 0, 0, 0.1);

  :global(.tabs) {
    height: 100%;
    display: flex;
    align-items: center;
  }
}