

.content {
  padding: 0rem;
  width: calc(100% - 1rem);
  &.active {
    background-color: var(--color-success-light);
    &:hover{  color: #ffd7d7;}
  }

  &.edit {
    color: black;
    border-left:8px solid limegreen;
    &:hover {
      background-color: #9cff9c;
    }
    &:target {
      border-left:8px solid red;
    }
  }
  &.noedit {
    border-left:8px solid white;
    color: var(--gray-dark);
    &:hover {
      background-color: #f1f1f1f1
    }
    &:target {
      border-left:8px solid var(--gray-dark);
    }
  }
  &.old {
    background-color: var(--gray-light);
    color: var(--gray-dark);
  }
}

.entry {
  display: flex;
  flex-direction: row;

  .organizer {
    width: 4rem;
    color: var(--gray-dark);
    font-weight: bold;
    font-size: 1rem;
    font-family: Inter;

  }

  .meetingId {
    --background-color: var(--gray-light);
    color:var(--gray-dark);
    text-align: left;
    padding:0rem 0.2rem;
    font-family: Inter;
  }

  .organizername {
    color: white;
    text-align: center;
    padding: 0.2rem;
    font-family: Inter;
    font-size: 0.8rem;
    //background-color: gray;
    border-radius: 0.2rem;
    max-width: 2.5rem;


    &.lv {
      background-color: var(--dodgerblue);
    }

    &.ojv {
      background-color: red;
    }

    &.jv {
      background-color: lightseagreen;
    }
    &.eju {
      background-color: #ffa60d;
    }
    &.ijf  {
      background-color: #9d16cb;
    }
  }
  .name {
    word-break: break-word;
    border-left: 2px dotted var(--gray);
    padding-left: 0.5rem;
    flex: 2;
    font-weight: bold;

    .nennzeitraum {
      font-weight: normal;
      color: var(--gray-dark);
      font-size: 0.9rem;
    }
    .eventzeitraum {
      font-weight: bold;
      color: var(--dodgerblue);
      font-size: 0.9rem;
    }
  }

  .bundesweit {
    color: white;
    background-color: var(--gray);
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    margin: 0.25rem;
  }
  .local {
    color: var(--gray-dark);
    background-color: var(--gray-light);
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    margin: 0.25rem;
  }
  .open {
    color: white;
    background-color: var(--color-success);
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    margin: 0.25rem;
  }
  .soon {
    color: var(--color-success);
    background-color: white;
    border:1px solid var(--color-success);
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    margin: 0.25rem;
  }
  .closed, .nojama {
     color: var(--color-danger);
     background-color: white;
     border:1px solid var(--color-success);
     font-size: 0.75rem;
     padding: 0.25rem;
     border-radius: var(--border-radius);
     margin: 0.25rem;
   }
  .error {
    background-color: var(--color-danger);
    color: white;
    border:1px solid var(--color-success);
    font-size: 0.75rem;
    padding: 0.25rem;
    border-radius: var(--border-radius);
    margin: 0.25rem;
  }
}
.itemnavgroup{
  margin-bottom: 10rem;
}
