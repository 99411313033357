
.judges {
  padding: 0.5rem;
  width: 100%;
  .header {
    padding: 0.5rem;
    font-weight: bold;
    display: flex;
    .text {
      flex: 1;
      display:block;
      overflow: hidden;
    }
    .judoCard {

      background-color: var(--color-success);
      color: var(--text-bg);
      padding: 0.25rem;
      margin: -0.25rem 0.25rem 0;
    }
  }

  .content {
    padding: 0.5rem;
    font-weight: normal;
    color: var(--text-fg);
  }

  .age {
    font-weight: bold;
    color: var(--text-fg)
  }

}

.save {
  width: 100%;
}

.select {
  display: flex;
  .dropdown {
    flex: 1
  }

  .clear {

    margin-left:0.5rem;
    font-size: 1.2rem;
  }
}

.save {
  margin: 2rem auto;
  border-radius: 0.5rem;
  display: flex;
  font-size: 1.2rem;
  justify-content: center;
  align-items: center;
  max-width:99%;
  background-color: #29a929 !important;
  &:hover {
    background-color: green !important;
    border: 0px!important;
  }

  &:disabled {
    background-color: var(--gray-dark) !important;
  }
}

.itemnavgroup{
  margin-bottom: 10rem;
}
