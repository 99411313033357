.rightside {
  margin: 1rem 1rem 5rem 1rem;
  paddding:1 rem;

  .traininghistory {
    color: #007bff;
    font-size: 1.3rem;
    font-weight: bold;
    text-transform: uppercase;
    text-align: center;
    border:0;
  }

  .row {
    border: 1px solid gray;
    background-color: #8ec8ff;
    margin-bottom: 2rem;
    border-radius: 0.3rem;
    //border:2px solid red;

    label {
      color: black;
      text-decoration: none !important;
    }

    .infoItem {
      background-color: var(--gray-super-light);

      &.processing {
        background-color: #ebf5ff;
      }
      &.processingStatus {
        background-color: lightgreen;
      }
      & * {
        text-decoration: none !important;
      }
    }

    div {
      border-bottom: 1px solid silver;
    }


  }

  .straf {
    padding: 0.5rem;
   .toggle {
     background-color: lightgreen;
   }
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  .trainerLizenz {
    options {
      max-width: 5rem;
      //color: red;
    }
  }

  //.custom-select-container .custom-select-option {
  //  max-width: 200px; /* Set your desired max-width */
  //  white-space: nowrap; /* Prevent text from wrapping */
  //  overflow: hidden; /* Hide overflow text */
  //  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  //}

  .lastitem {
    margin-bottom: 4rem;
    //border:2px solid red;
  }
}