
.item {
  //background-color: var(--text-bg);
  color: var(--text-fg);
  .header {
    width: 100%;
    margin-top: 1rem;
    padding: var(--spacing);
    text-align: center;
    color: var(--gray);
    //background-color: var(--gray-light);
    //background-color: var(--gray);
    border-bottom: 2px solid var(--gray);
    font-weight: bold;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0.1rem;
    word-spacing: 0.5rem;
  }

  .children {

  }


  &.spacerTop {
    margin-top: 2rem;
    border: 1px solid red;
  }
}