$padding: var(--spacing);

$imageWidth: 9rem;

.judocard {
  margin: calc(2 * var(--spacing)) var(--spacing);
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  background-color: white;
  // border: 1px solid $color_background;
  // border-bottom: 1px solid black;
  // border-bottom: 1rem solid $color_success;
  border-radius: 1rem;
  overflow: hidden;
  //border: 0.5rem solid white;

  &.disabled {
    // background-color: $color_danger;
    // border-bottom: 1rem solid $color-danger;
  }

  .card {
    display: flex;
    border-bottom: 0.5rem solid var(--color-success);
    overflow: hidden;

    .imageContainer {
      width: $imageWidth;
      height: 12rem;

      .image {
        display: block;
        width: $imageWidth;
        height: 12rem;
        object-fit: cover;
      }

      .uploadImage {
        position: absolute;
        border-radius: 100%;
        margin-top: -2.75rem;
        margin-left: 0;
      }
      .camera {
        color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(0.25rem);
        padding: var(--spacing);

      }
    }

    .label {
      width: 100%;
      max-width: calc(100vw - calc(calc(6 * var(--spacing)) + #{$imageWidth}));
      padding: 0.5rem;
      font-size: 0.8rem;
      p {
        margin: 0;
      }

      .title {
        font-size: 1rem;
        font-weight: bold;
      }

      .subtitle {
        font-size: 0.8rem;
        font-weight: bold;
      }
    }
  }

  &.disabled {
    .card {
      // background-color: $color_danger;
      border-bottom: 0.5rem solid var(--color-danger);
      // border-bottom: 1rem solid $color-danger;
    }
  }

  .medical {
    width: 3rem;
    height: 3rem;
    float: right;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../theme/editions/judoaustria/images/red_cross_56x56.png");
  }

  .svg {
    margin-top: 0.75rem;
    width: 100%;
    background-color: var(--text-bg);

    svg {
      width: 100%;
    }
  }
}