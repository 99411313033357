$padding: var(--spacing);
$imageWidth: 4rem;

.judocard {
  margin: calc(2 * var(--spacing)) var(--spacing);
  @media print {
    box-shadow: 0 0 0 0;
    border-radius: 0rem;
    background-color: white;
    margin: 0px ;
  }
  box-shadow: 0 0 0.25rem 0.25rem var(--gray-light);
  background-color: white;
  border-radius: 0.25rem;
  overflow: hidden;
  transition: linear 150ms;
  background-color: rgba(var(--dodgerblue-rgb),0.1);
  position: relative;
  //margin-bottom: 10px;

  &.created,   &.submitted, &.active, &.submitted-by-kyu  {
    background-color: rgba(var(--dodgerblue-rgb),0.3);
    .card {
      @media print {
        border-bottom: 1px solid black;
      }
      border-bottom: 0.5rem solid rgba(var(--dodgerblue-rgb),0.7);
    }
  }

  //&.submitted-by-kyu {
  //   .card {
  //     border-bottom: 0.5rem solid blue;
  //   }
  // }

  .card {
    display: flex;
    flex-direction: row;
    //border-bottom: 0.5rem solid var(--color-success);

    border-bottom: 0.5rem solid var(--gray);
    overflow: hidden;
    position: relative;
    z-index: 1;

    .imageContainer {
      @media print {
        display: none !important;
     }
      width: $imageWidth;
      height: $imageWidth;

      .image {
        display: block;
        width: $imageWidth;
        height: $imageWidth;
        object-fit: cover;
      }

      .uploadImage {
        position: absolute;
        border-radius: 100%;
        margin-top: -2.75rem;
        margin-left: 0;
      }
      .camera {
        color: var(--primary-color);
        background-color: rgba(255, 255, 255, 0.2);
        backdrop-filter: blur(0.25rem);
        padding: var(--spacing);

      }

    }

    .content {
      //width: 100%;
      .text {
        //flex: 1;
        //display: flex;
        //width: 100%;
        //max-width: calc(100vw - calc(calc(6 * var(--spacing)) + #{$imageWidth}));
        padding: 0.5rem;
        font-size: 0.8rem;
        @media print {
          padding: 0.2rem;
        }
        p {
          margin: 0;
        }

        .name {
          @media print {
            font-size: 0.8rem;
          }
          flex: 1;
          font-size: 1rem;

          // Only hide spans with hide-for-print class
          span.hide-for-print {
            @media print {
              display: none !important;
            }
          }
        }
      }

      .error {
        flex: 1;
        padding: 0.5rem 1rem ;
        border-top: 2px dashed var(--color-danger);
        color: var(--color-danger);
        font-weight: bold;
        //border-radius: .5rem;
        //background-color: var(--color-danger);
        //background-color: gray;
      }
    }

  }

  &.disabled {
    .card {
      // background-color: $color_danger;
      @media print {
        border-bottom: 1px solid black;
      }
      border-bottom: 0.5rem solid var(--color-danger);
      // border-bottom: 1rem solid $color-danger;
    }
  }


  .svg {
    margin-top: 0.75rem;
    width: 100%;
    background-color: var(--text-bg);

    svg {
      width: 100%;
    }
  }

  .candidatesContainer {
    max-height: calc(100vh - 22rem); // Adjust based on your header/navigation height
    overflow-y: auto;
    position: relative;
    scroll-behavior: smooth;

    // Optional: Style scrollbar
    &::-webkit-scrollbar {
      width: 8px;
    }

    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 4px;
    }

    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .topButton {
    position: absolute;
    font-size: 1rem;
    right: 2rem;  // Adjust position from right edge
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;  // Ensure button stays above card
    color: white;
    border: none;
    border-radius: 0.3rem;
    width: 8rem;
    height: 2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.2s;
    padding: 0;
    box-shadow: 0 2px 4px rgba(0,0,0,0.2);  // Optional: adds some depth
  }
}
//}

.filter-judocards {
  ion-label {
    min-width: 30%;
  }
}

.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  .name {
    flex: 1;
    //width: calc(100% - 11rem);
    margin: 0.25rem;
  }

  .toggle {
    flex: 1;
    width: 9rem;
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: bold;
    //margin: 0.25rem;
    color: var(--text-bg);
    background-color: var(--primary-color);
    border: none;

    padding: 0.5rem;
  }
}

.selected {
  color: white;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  font-size: 2rem;
}
.delete {
  &:hover {
    background-color: red;
  }
  margin-top: 2rem !important;
  &.disabled {
    opacity: 1;
    background-color: #bbb;
    cursor: not-allowed;
  }
}

.submit, .approve  {
  background-color: var(--primary-button);
  width: 100%;
  &:hover {
    background-color: var(--primary-button-hover);
  }
}


.list {
  color: red;
  margin: 0.5rem;
}

.submittedkyu {
  background-color: var(--kyu) !important;
  max-width:30rem;
  transition: all 0.2s ease-in-out;
  &:hover {
    //filter: brightness(0.9);
    background-color: color-mix(in srgb, var(--kyu), black 20%) !important;
  }
}

.top {
  margin: 1rem;

  .orderHeader {
    margin: 1rem 0;

    @media print {
      text-align: center;
      margin-bottom: 2rem;
    }
  }

  .orgName {
    font-weight: bold;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .orderDetails {
    display: flex;
    gap: 2rem;
    color: var(--gray-dark);
    font-size: 0.9rem;

    @media print {
      justify-content: center;
      margin-top: 0.5rem;
    }

    @media screen and (max-width: 768px) {
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      scrollbar-width: thin;
      padding-bottom: 0.8rem; // Increased padding to accommodate larger scrollbar

      // Increased scrollbar height
      &::-webkit-scrollbar {
        height: 10px; // Increased from 6px to 10px
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px; // Slightly increased for better proportion
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb:hover {
        background: #555;
      }
    }
  }
}
.filteredItemsContainer{
  margin-bottom: 10rem;
}
