.profile {
  position: relative;
  width: 210px;
  min-width: 210px;
  max-width: 280px;
  max-height: 450px;
  padding: 0.5rem;
  padding-bottom: 1rem;

  .image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 0.3rem;

  }

  .controls {
    //position: absolute;
    bottom: 0;
    right: 0;
    display: flex;
    gap: 0.3rem;
    background: rgba(255, 255, 255, 0.9);
    border-radius: 0.3rem;
    //margin: 8px;
    width: 100%;

    &.cameraButton {
      background: white;
      border-radius: 0.3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: auto;
      padding: 0;
      border: 1px solid #ddd;
      cursor: pointer;
    }

   &.deleteButton {
      width: 25%;
      &:hover{
        background-color: red !important;
      }
    }

  }
}


.camera {
  margin-left: 0.25rem;
}

.form {
  background-color: #ffffff;
  margin-bottom: 2rem;
  .formheader{
    background-color: lightgreen !important;
  }
  .header {
    display: flex;
    flex-direction: row;
    width: 100%;

    .profile {
      flex: 1;
      margin-right: 0.5rem;
      padding-right: 0.25rem;
    }

    .top {
      flex: 3;
      min-width: 75%;
    }
  }

  .speichern {
    margin-top: 2rem !important;
    margin-bottom: 2rem !important;
  }

}

.somePadding {
  margin: 0.5rem;
}


//.row {
//  max-height : 1rem;
//  vertical-align: center;
//  &:nth-child(even) {
//    background-color: #ebf1ff;
//  }
//}


@media only screen and (max-width: 600px) {
  .form {
    .header {
      display: block;

      //flex-direction: column;

      //flex-direction: column;
    }
  }
  .image {
    height: 20rem;
  }
}

.history {
  margin-bottom: 2rem;
}

.dropDown {
  height:1.8rem !important;
}
h4 {
  margin: 0.5rem;
  text-align: center;
  color:var(--primary-color);
}

.enddateitem {
  input{
    background-color: white;
    width:5rem;
  }
  min-width: 7rem !important;
}

.rolegroup{
  font-size: 0.8rem;
}

.rowheight >  {
//
}

.spacerTop{
  margin-top: 6rem;
  border-bottom: 2px solid var(--gray);
  border-collapse: collapse;

  .row:last-child{
    margin-bottom: 10rem;
  }
}

.vereinswechsel, .deaktivieren, .verstorben{
  margin-bottom:3rem;
  margin-top:0rem !important;
  &.verstorben {
    &:hover {
      background-color: red !important;
    }
  }
}

.deleteButton {
  &:hover {
    background-color: red !important;
    cursor: pointer;
  }
}

.itemnavgroup{
  margin-bottom: 10rem;
}
