.input {
  color: white;
  min-height: 2.5rem;
  padding: var(--spacing) calc(2 * var(--spacing));
  border-radius: var(--border-radius);
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s ease; // Add smooth transition
  margin:0.5rem;
  &.full {
    width: calc(100% - 1rem - var(--spacing));
  }

  &.primary {
    background-color: var(--primary-button);

    &:hover {
      background-color: var(--primary-button-hover);
    }
  }

  &.success {
    background-color: var(--primary-button);

    &:hover {
      background-color: var(--primary-button-hover);
    }
  }

  &.light {
    background-color: var(--gray-super-light);
    color: var(--gray-light);

    &:hover {
      background-color: var(--gray-light);
      color: var(--gray-super-light);
    }
  }

  &:disabled {
    background-color: var(--gray);
    cursor: not-allowed;
    &:hover {
      background-color: var(--gray-light); // Maintain disabled state on hover
    }
  }
}

.buttonWrapper {
  position: relative;
  display: inline-block;

  button[data-tooltip] {
    position: relative;
  }

  button[data-tooltip]:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 0.5rem;
    background-color: rgba(20, 20, 20, 0.7);
    backdrop-filter: blur(8px) !important; // Add blur effect
    -webkit-backdrop-filter: blur(8px) !important; // For Safari support
    color: white;
    border-radius: var(--border-radius);
    font-size: 1rem;
    font-weight: bold;
    white-space: pre-line;
    z-index: 1000;
    margin-bottom: 5px;
    min-width: 12rem;
  }

  button[data-tooltip]:hover::before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    border: 5px solid transparent;
    border-top-color: rgba(0, 0, 0, 0.8);
    margin-bottom: -5px;
  }
}