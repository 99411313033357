
.item {
  background-color: var(--text-bg);
  color: var(--text-fg);
  padding: var(--spacing);
  display: flex;
  width: 100%;
  border-bottom: 1px solid var(--gray);
  transition: linear 150ms;
  cursor: pointer;

  /*&:nth-child(2n + 1) {
    background-color: var(--gray-super-light);
  }*/

  &.selected {
    background-color: var(--primary-color-super-light);
  }

  .icon {
    font-size: 1.1rem;
    padding-right: 1rem;
  }

  .label {
    font-size: 1.1rem;
    cursor: pointer;

  }

  &.primary {
    color: var(--text-bg);
    background-color: var(--primary-color);
    width: calc(100% - 2rem);
    border-radius: var(--border-radius);
    margin: 0.5rem;
    overflow: hidden;
  }

  .content {
    flex: 1;
    padding: var(--spacing);
    // line-height: 2rem;
  }

  .arrow {
    margin: auto 0;
    vertical-align: center;
    width: 2rem;
    color: var(--gray-light)
  }
}
  .item.disabled {
    cursor: not-allowed;

    .icon {
      color: red;
    }
  }

.center {
  display: inline-block;

   text-align: center;
   justify-content: center;
  width: calc(100% - 4rem);
}

.right {
  text-align: right;
}