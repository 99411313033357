@import "../../../theme/global";

.gallery-content {
  // --background: #{$color_highlight};
  // height: calc(100% - 88px);


  //img {
  //  max-width: 100% !important;
  //  width: auto !important;
  //  height: auto !important;
  //}
}

.gallery {
  display: flex;
  flex-direction: column;
  //border-radius: 0 !important;
  position: relative;
  min-height: 14.2rem;
  border: unset;
  justify-content: end;

  margin: .4rem .2rem;

  line-height: 3;
  text-shadow: 1px 2px 2px rgba(0, 0, 0, 1) !important;
//   color: #fff;

  background: center no-repeat;
  background-size: cover;

  &Img {
    maring-top: var(--spacing);
    margin-bottom: var(--spacing);
  }
  .gallery-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }

  .no-content {
    display: flex;
    position: absolute;
    top: 30%;
    justify-content: center;
    width: 90%;
    color: #808285 !important;

    & h1 {
      text-shadow: unset !important;
    }

  }

  .gallery-content {

    --display: grid !important;
    grid-template-columns: 1fr 1fr;

    .title {
      background-color: #fff;
    }
    .content {

    }
  }

  img {
    max-width: 100% !important;
    width: auto !important;
    height: auto !important;
  }



  .data-content {

    background-color: rgba(0, 0, 0, 0.5);
    position: relative;
    padding: 0 1rem 1rem 1rem;

    .title {
      font-size: 1rem;
      font-weight: 600;
     // color: #fff;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .date {
      font-size: 1rem;
      font-weight: 500;
     //color: #fff;
    }
  }

  .image-counter {
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 1rem;
    // color: #fff;
    top: 0;
    right: 0;
    font-family: "Cambria Math", serif;
    height: 1rem;

    & .counter-content {
      height: max-content;
      margin-left: .2rem;
    }
  }

}

.collection-card {
  //max-height: 94%;
  display: flex;
  flex-direction: column;
  margin: calc(env(safe-area-inset-top) + 1rem) 0 calc(env(safe-area-inset-bottom) + 1rem);


  .collection-card-header {
    display: flex;
    width: 100%;
    padding: 0;
    // position: sticky;
    border-bottom: 1px #9ba1a6 solid;
    z-index: 6;

    .title {
      padding: 1rem;
    }

  }

  .content {
    /*overflow: hidden;
    overflow-y: auto;
    ::-webkit-scrollbar {
      display: none;
    }*/
    margin-bottom: 5rem;
  }
}

.collection-close-button {
  margin: 0;
  min-height: 2rem !important;
}

@media screen and (max-width: 350px){
  .gallery {
    min-height: 10rem;
    font-size: 1rem;
  }
}

@media screen and (min-width: 410px){
  .gallery {
    min-height: 13rem;
    font-size: 1rem;
  }
}

@media screen and (min-width: 560px){
  .gallery {
    min-height: 9rem;
    font-size: .4rem;
  }
}

@media screen and (min-width: 650px){
  .gallery {
    min-height: 10rem;
    font-size: .4rem;
  }
}

@media screen and (min-width: 700px){
  .gallery {
    min-height: 11rem;
    font-size: .4rem;

  }
}

@media screen and (min-width: 760px){
  .gallery {
    min-height: 12rem;
    font-size: .4rem;
  }
}

@media screen and (min-width: 920px){
  .gallery {
    min-height: 16rem;
    font-size: .4rem;

    .date {
      font-size: 1.2rem;
    }

    .title {
      font-size: 1.5rem;
    }

  }
}

@media screen and (min-width: 1200px){
  .gallery {
    min-height: 15rem;
    font-size: .4rem;

  }

}
@media screen and (min-width: 1250px){
  .gallery {
    min-height: 20vw;
    font-size: .4rem;
  }
}

@media screen and (min-width: 1800px){
  .gallery {
    min-height: 20vw;
    font-size: .4rem;

    .date {
      font-size: 1.5rem;
    }

    .title {
      font-size: 2rem;
    }

  }
}
