.item {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
  img {
    width: 4rem;
    height: 5rem;
    object-fit: cover;
  }

  .quantity {
    min-width: 2rem;
    text-align: right;
    font-weight: normal;
    font-size: 1.2rem;
    ;
  }
  .info {
    flex: 1;
  }
  .title {
    flex: 1;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .buttons {
    float: right;
    display: flex;
    gap: 0.5rem
  }
}