

.segment {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% - var(--spacing));
  justify-content: center;
  border-radius: var(--border-radius);
  overflow: hidden;
  margin: calc(0.5 * var(--spacing));
  font-size: 1rem;
  cursor: pointer;
  @media (max-width: 600px) { // Apply these styles when the screen width is 600px or less
    //flex-direction: column; // Stack the items vertically
  }
  .option {
    flex: 1;
    flex-basis: 10%; // Adjust this value to control the number of elements per row
    text-align: center;
    padding:var(--spacing) calc(2 * var(--spacing));
    border: 1px solid white;
    background-color: var(--gray-light);
    transition: 200ms ease-in;

    &.disabled, &.optionDisabled {
      background-color: var(--gray-light);
      color: var(--gray)
    }
    &.active {
      background-color: var(--primary-button-color);
      color: white;
      &:hover {
        background-color: var(--primary-button-color);
        filter: brightness(115%);
        color: white;
      }
    }
    &:hover {
      background-color: var(--gray);
      //filter: brightness(400%);
      color: white;
    }
  }
}