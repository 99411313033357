
$padding: 0.25rem;

.nominations {

  width: 100%;
  min-height: 100%;
  // background-color: $color_highlight;
  padding: $padding $padding 5rem;

  .judocard {
    margin: 1rem 0.5rem;
    // background-color: $color_background;
    // border: 1px solid $color_background;

    &.selected {
      //background-color: $color_success;
    }

    &.disabled {
      //background-color: $color_danger;
    }

    .card {
      display: flex;
      .image {
        max-height: 5rem;
        width: auto;
      }
      .label {
        padding: 0.5rem;
        p {
          margin: 0;
        }
        .title {
          font-size: 1.2rem;
          font-weight: bold;
        }
        .subtitle {
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }
  }
}

