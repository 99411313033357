.menuitem {
  padding: 0.5rem 0.2rem;
  font-size: 1rem;
  border-bottom: 1px solid var(--gray-light);

  .button {
    font-size: 1rem;
  }

  .link {
    text-decoration: none;
    &:hover {
      background-color: var(--primary-color);
      color:white;
    }
  }

  .version {
    //margin: 0.5rem 0rem 0.5rem 0rem;
    font-size: 1rem;
    color: var(--gray);
  }

  .user {
    font-size: 1rem;
    color: black;
    margin-top: 5px;
  }

}
div {
  font-size: 1rem;
}

.judocard {
  color: limegreen;
  padding: 0.5rem 0.2rem;
  font-weight: bold;
}