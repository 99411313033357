
$padding: 0.25rem;
$vh: calc(100vh - calc(calc(2 * var(--frame-height)) + calc(env(safe-area-inset-top) + env(safe-area-inset-top))));

$color_judocard: #62c1ff;
$color_highlight: #808285;
$color_background: #fff;
$color_text: black;

.judo {
  width: 100%;
  height: $vh;
  // background-color: $color_highlight;
  //padding: $padding;

  &Card {
    position: absolute;
    transform: rotate(90deg);
    width: calc(#{$vh} - calc(8 * var(--spacing)));
    height: calc(100vw - calc(8 * var(--spacing)));
    // margin-left: calc(100% - #{$padding * 2});
    transform-origin: left top;
    background-color: $color_background;
    padding: 1rem;
    margin-left: calc(100vw - calc(var(--spacing) * 2));
    margin-top: calc(2 * var(--spacing));
    border: 2pt solid var(--gray-light);
    box-shadow: 0 0 0.5rem 0.5rem var(--gray-super-light);
    border-radius: 2rem;


    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-content: center;
      height: 100%;
      padding-right: 1rem;
      width: calc(100% - calc(min(37.5vw, 25vh))); //calc( 100% - calc(0.4 * #{$vh}));

      div {
        flex:1;
        // border: 1px solid red;
      }
    }


	.name {
        font-size: calc(0.035 * #{$vh});
	}
    h1 {
      margin-top: 0;
    }

    .title {
      flex: 2;
      // display: flex;
      // justify-content: space-between;
      h1 {
        margin-top: calc(0.005 * #{$vh});
        margin-bottom: 0.25rem;
        color: #ccc;
        font-size: calc(0.06 * #{$vh}); //  4rem;
        font-weight: bold;
        display: inline-block;

        span {
          color: $color_judocard;
        }
      }
        .logo {
          position: absolute;
          // float: right;
          right: 1rem;
          top: 1rem;
          display: inline-block;
          height: calc(0.09 * #{$vh});
          width:calc(0.2 * #{$vh});
          margin-top: calc(-0.01 * #{$vh});
          margin-bottom: calc(0.05 * #{$vh});
          margin-left: 3rem;
          //background-image: url("../../../theme/editions/judoaustria/images/logo.png");
          background-position: center;
          background-size: cover;
        }

    }

    .birthdate {
      margin-top:0.1rem;
      margin-bottom:0.2rem;
    }

    label {
      margin-top: 0.1rem;
      font-size: calc(0.03 * #{$vh}); //  1.5rem;
      display: block;
    }

    .grades {
      .grade {
        display: inline-block;
        width: 3rem;
        height: 2rem;
        border: 1pt solid $color_highlight;
      }

      .maxgrade {
        margin-left: 1rem;
        vertical-align: top;
        line-height: 2rem;
      }
    }

    .beltContainer{
      display: inline-block;
      label {
        margin-top: 0rem;
        height:2.8vh;
      }

      .belt {
        margin-left:0.5rem;
        margin-top: 0.1rem;
      }

      .belt svg  {
        height: 2.4vh;
        width:auto;
        border:1px solid black;

      }
    }

    .barcodeContainer {
      display: flex;

      .plus {
        // width: fit-content;


        background-image: url("../../../theme/editions/judoaustria/images/plus.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 2.5rem;
        max-width: 2.5rem;
        min-width: 1.5rem;
        height: 2.5rem;
        margin-top: 0.8rem;
        margin-left: 0.1rem;
      }
      .plusPlaceholder {

        background-image: url("../../../theme/editions/judoaustria/images/plus-placeholder.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 2.5rem;
        max-width: 2.5rem;
        min-width: 1.5rem;
        height: 2.5rem;
        margin-top: 0.3rem;
        margin-left: 0.1rem;
      }

      .barcode {
        width: fit-content;
        margin-left: 1rem;
      }
    }

    .gender {
      margin: 0 0 0 0.7rem;
      padding:0;
      line-height: 1.5rem;
      font-size: 1.5rem;
      font-weight:900;
    }
    .femal {
      color:deeppink;
    }
    .male {
      color:#428cff;
    }
    .genderx {
      color:black;
    }
    .profile {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      width: calc(min(37.5vw, 25vh));
      height: calc(min(60vw, 40vh));
      border-radius: 1rem;

      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .sponsor {
    // width: calc(.25 * #{$vh});
    height: calc(0.08 * #{$vh});
    max-width: 10rem;
    //background-image: url("../../../theme/editions/judoaustria/images/logo-burgenland.jpg");
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
  }
}
