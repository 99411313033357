// src/components/foundation/ImageCropDialog.module.scss

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.dialog {
  background: white;
  border-radius: 8px;
  padding: 20px;
  width: 90%;
  max-width: 800px;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  h3 {
    margin: 0;
  }
}

.closeButton {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0 8px;

  &:hover {
    opacity: 0.7;
  }
}

.content {
  flex: 1;
  min-height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
}

.uploadArea {
  border: 2px dashed #ccc;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
  position: relative;
  cursor: pointer;
  min-height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    border-color: #999;
  }
}

.fileInput {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

.uploadText {
  color: #666;
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
}

.cancelButton, .saveButton {
  padding: 8px 16px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-weight: 500;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

.cancelButton {
  background-color: #f0f0f0;
  color: #333;

  &:hover:not(:disabled) {
    background-color: #e0e0e0;
  }
}

.saveButton {
  background-color: #007bff;
  color: white;

  &:hover:not(:disabled) {
    background-color: #0056b3;
  }
}