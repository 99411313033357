.title {
    font-size: 1.1rem;
    font-weight: 500;
}

.date {
    color: #666;
}

.types {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.type {
    font-size: 0.875rem;
    padding: 4px 12px;
    border-radius: 0.3rem;
    background-color: #e0e7ff;
    color: #4338ca;
}

.abstract {
    color: #666;
    margin: 8px 0;
    
    :global {
        p {
            margin-bottom: 8px;
        }
    }
}

.content {
    margin: 8px 0;
    
    :global {
        h1, h2, h3, h4, h5, h6 {
            margin-bottom: 12px;
            font-weight: 600;
        }
        
        p {
            margin-bottom: 8px;
        }
        
        strong {
            font-weight: 600;
        }
        
        i {
            font-style: italic;
        }
    }
}

.attachments {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 16px;
    margin: 8px 0;
}

.attachment {
    border: 1px solid #e5e7eb;
    border-radius: 0.3rem;
    overflow: hidden;
    
    .image {
        width: 100%;
        height: auto;
        display: block;
    }
    
    .fileName {
        padding: 8px;
        font-size: 0.875rem;
        color: #666;
    }
}

.tags {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.tag {
    font-size: 0.875rem;
    padding: 2px 8px;
    border-radius: 0.3rem;
    background-color: #f3f4f6;
    color: #666;
}
