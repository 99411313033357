.tab {
  display: block;
  flex: 1;
  justify-content: center;
  margin-top: 0.25rem;
  padding: 0.5rem;
  cursor: pointer;
  &:hover {
    background-color: var(--primary-color-super-light);
  }
  margin-right: 0.25rem;
  border-top-left-radius: 1rem;
  background-color: #e8e8e8;
  .label {
    transition: 1s;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    text-align: center;
    color: var(--gray);
  }

  &.default {

  }

  &.compressed {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    border-bottom: 1px solid var(--gray-light);
    .label {
      font-size: 1rem;
    }
  }
  .icon {
    transition: 1s;

    font-size: 1.5rem;
    text-align: center;
    color: var(--gray);
  }


  &.active {
    .icon {
      text-align: center;
      color: white;
    }

    .label {
      text-align: center;
      color: white;
    }
    background-color: var(--primary-color);
    color:white;
    border-top-left-radius: 1rem;
  }
}