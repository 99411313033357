
.container {
  border-bottom: 0.5rem solid var(--gray);
  width: 92%;
  margin: 1rem;
  .list {
    width: 100%;
  }
}

.question {
.title {
  flex: 1;
  padding-left: 1rem;
  font-weight: bold;
  color: blue !important;
}
 &>select {
    font-size: 1.2rem;
  }


  select {
    border: none !important;
    outline: none;
    display: flex;
    max-width: 98%;
    flex-direction: row;
    border: none;

//option {
//  margin-right: 2rem;
//}

  .answerItemMultiSelect {
    position: relative;
    margin-left: 1rem;
    word-wrap: break-word;
    margin-right: 1rem;

    &:before {
      content: '\2610';
    }
    &.checked::before {
      content: '\2611';
    }
  }
}
//.answerItem {
//  display: flex;
//  align-items: center;
//}
//
//.answerItem::before {
//  content: '\f14a';
//  width: 20px;
//  height: 20px;
//  background-color: lightgrey;
//  margin-right: 10px; /* Adjust spacing as needed */
//}

}