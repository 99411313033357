.statistics {
  margin: 1rem;
}

.header {
  background-color: var(--background-color);
  font-weight: bold;
  border-bottom: 2px solid red;
}

// Using the Item component's .children class structure
:global(.item) {
  .children:nth-child(2) {
    color: red !important;
  }
}

// Row styles
:global(.row) {
  border-bottom: 1px solid var(--gray-light);
  &:hover {
    background-color: var(--background-color);
  }
}

.total {
  div {
    font-weight: bold;
    color: var(--gray);
    text-align: right;
  }
}

.dateRange{
  margin:2rem;
}

.children {
  padding-left: 1rem !important;
  color: var(--primary-color);
  font-weight: bold;

  &.editor {
    font-size:1rem;
    color: var(--gray-dark);
  }
}

.sortableHeader {
  cursor: pointer !important;
  user-select: none;

  &:hover {
    background-color: var(--background-color) !important;
  }

  .children {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
}


.excelTable {
  border-collapse: collapse;
  width: 100%;
  margin: 10px 0;

  th, td {
    border: 1px solid #ddd !important;
    padding: 8px;
    text-align: left;
  }

  th {
    cursor: pointer;
    background-color: #f5f5f5;

    &:hover {
      background-color: #e5e5e5;
    }
  }

  tbody tr:nth-child(1) {
    font-weight: bold;  // Making the "Gesamt" row bold
    background-color: #f9f9f9;
  }
}