.image {
  width: 100%;
  object-fit: cover;
  height: 15rem;
}

.camera {
  margin-left: 0.25rem;
}

.form {
  margin-bottom: 8rem;
}

.somePadding {
  margin: 0.5rem;
}