.tournament {
  height: var(--content-height);
  overflow: hidden;

//  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;

  .info {
    padding: var(--spacing);
    //height: calc(calc(0.5 * var(--content-height)) - calc(var(--spacing) * 2));
    height: 100%;

    .spacer {
      margin-top: 1rem;
      margin-bottom: 1rem;
    }

    a {
      text-decoration: none;
      color:blue;
    }

    overflow-x: scroll;
    h2 {
      margin: var(--spacing) 0;
      font-size: 1.25rem;
    }

    h3 {
      font-size: 1rem;
      font-weight: 600;
      color: var(--gray);
      text-transform: uppercase;
      margin: 0;
    }

    .head {
      display: flex;
      flex-direction: row;
      .title {
        flex: 1;
        letter-spacing: -0.05rem;
        h2 {
          font-size:1.1rem;
          font-weight: bold;
        }
      }

      .date {
        border: 2px solid var(--light-blue);
        max-height: 6rem;
        .month {
          padding: var(--spacing);
          background-color: var(--light-blue);
          text-align: center;
          text-transform: uppercase;
          color: white;
        }

        .day {
          padding: 0 var(--spacing);
          color: var(--primary-color);
          font-size: 1.8rem;
          text-align: center;
        }

        .weekday {
          padding: 0 var(--spacing);
          text-align: center;
          color: var(--gray);
          font-size: 0.8rem;
        }
      }
    }
    hr {
      border-color: var(--gray-light);
      border-style: solid;
    }
  }

  .map {
    width: 100vw;
    position: relative;

    min-height: calc(0.5 * var(--content-height));
//    height: calc(50vh - 5.5rem);
    //position: absolute;
    //bottom:0;
    //left:0;
    //right:0;

    overflow: hidden;

    .address {
      text-align: center;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      z-index: 1000;
      backdrop-filter: blur(0.5rem);
      padding: var(--spacing);
    }
  }

  .item {
    margin-top: var(--spacing);
    display: flex;
    flex-direction: row;
    width: 100%;

    .share {
      flex: 1;
      justify-content: right;
      text-align: right;
      color: var(--primary-color);
      background-color: white;
      padding: 0 var(--spacing);

    }
  }

  .card{
    border: 1px solid #b6b8be;
    margin:2rem 1rem;
    border-radius: 0.3rem;
    background-color: #E0E8F6;
    padding:1rem 1rem;
    box-shadow: 0 0 0rem 0rem #b6b8be;

    h2 {
      font-size: 1.0rem;
      font-weight: bold;
      margin: auto;
    }
    .essen button{
      margin-right: 1rem;
      &:last-child {
        margin-right: 0; // Removes the margin on the last button container
      }
    }
    .fee,
    .enrolled {
      margin-top: 1.5rem;
      height: 3.5rem;

    }

    .enrolled {
      background-color: lightgreen;
      color: darkblue;
      font-weight: bold;
    }
  }
}

