
.header {
  width: 100%;
  height: 3rem;
  padding: 1rem;
  //background-color: var(--primary-color);
  background: radial-gradient(circle at top right, rgba(var(--primary-color-rgb), 0.95) 0,  rgba(var(--primary-color-dark-rgb), 0.9) 100%);
  //border-bottom: 1px solid var(--gray);
  display: flex;
  //gap: 1rem;
  flex-direction: row;
  justify-content: center;
  //margin-bottom: 1rem;
  //box-shadow: var(--shadow);
  //max-width: var(--max-content-width);
  //padding: 0 var(--content-padding);
  .item {
    flex: 1;

    .select {
      width: calc(100% - 2rem);
    }
  }

  .logo {
    width: 5rem;
    height: 2.5rem;
    margin: 0 2rem;
    background-image: var(--logo-white);
    background-size: 100%;
  }
}