.headline {
    .profile {
      //overflow-y: scroll;
      display: flex;
      flex-direction: column;
      backdrop-filter: blur(0.5rem);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin: 1rem auto;

      h1.name {
        font-size: 2rem;
        padding:0.5rem;
        text-align: center;
        margin: 0 auto;
        color: var(--gray-dark)
      }
  }

  .headercontainer {
    padding-right: var(--spacing);
    padding-left: var(--spacing);
    @media only screen and (max-width: 768px) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
    .profilecontainer {
      position: relative;
      //width: auto;
      //height: 450px;
      background-color: white;
      padding-bottom: 5rem;
      @media only screen and (max-width: 768px) {
        margin: 2rem auto;  // Added auto margins for horizontal centering
        display: flex;      // Added flex display
        flex-direction: column;  // Stack elements vertically
        align-items: center;     // Center items horizontally
      }
      .image {
        object-fit: cover;
        height: auto;
        width:100%;
        max-width:16rem;
        border-radius: 0.3rem;
        margin-top: 0.5rem;
        border:1px solid silver;
        @media only screen and (max-width: 768px) {
          margin: 0.5rem auto;  // Center image with auto margins
        }
      }

      .controls {
        //position: absolute;
        bottom: 0;
        right: 0;
        display: flex;
        gap: 0.3rem;
        background: rgba(220 , 220, 220, 1);
        border-radius: 0.3rem;
        width: 100%;


        &.cameraButton {
          background: white;
          border-radius: 0.3rem;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 30% !important;
          height: auto;
          padding: 0rem !important;
          border: 1px solid #ddd;
          cursor: pointer;
          margin: 0px !important;

        }

        &.deleteButton {
          width: 25% !important;
          &:hover{
            background-color: red !important;
          }
        }
      }
    }
  }

  .container {
    margin:2rem;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap into the next column */
    //justify-content: space-between; /* Distribute space between items */
    justify-content: flex-start; /* Changed from space-between to ensure consistent spacing */
    width: calc(100% - 4rem);
    @media only screen and (max-width: 768px) {
      margin: 0.5rem;  // Reduced margin for mobile
      width: calc(100% - 1rem);  // Adjusted width calculation for mobile
    }
  }

  .bild {
    order:1;
  }

  .stammdaten {
    flex: 1 1 calc(50% - 1rem); /* Change to 33.333% for 3 columns */
    order: 2; /* Optional, ensures 'Prüfungen' is first */
    min-width: calc(60% - 1rem);
  }
  .pruefungen {
    flex: 1 1 calc(50% - 1rem); /* Change to 33.333% for 3 columns */
    order: 3; /* Optional, ensures 'Prüfungen' is first */
   }

  .trainerlizenz {
    flex: 1 1 calc(100% - 1rem); /* Change to 33.333% for 3 columns */
    order: 4; /* Optional, ensures 'Trainer-Lizenz' is second */
    //flex: 1 1 calc(100% - 1rem) !important; /* Span two columns */
    //max-width: calc(100% - 1rem) !important; /* Ensure max-width matches */
  }

  .info {
    padding-right: var(--spacing);
    padding-left: var(--spacing);

    .infoItem:last-child {
      margin-bottom: 20px; // adjust this value as needed
    }
    .infoItem {
      b {
        color: var(--primary-color);
      }
    }
    @media only screen and (max-width: 800px) {
      padding-right: 0.5rem;
      padding-left: 0.5rem;
    }
  }

  .infoItem:nth-child(odd) {
    background-color: var(--text-bg);
    border-top: 1px solid var(--gray);
    border-bottom: 1px solid var(--gray);
    color: var(--text-fg) !important;
    border-collapse: collapse;
  }

  .infoItem:nth-child(1) {
    border-top: 0;
  }

  .infoItem:nth-child(even) {
    background-color: var(--primary-color-super-light);
    border-bottom: 1px solid var(--gray);
    color: var(--text-fg) !important;
    border-collapse: collapse;
  }

  label {
    margin: calc(0.5 * var(--spacing));
  }
}


@media only screen and (max-width: 768px) {
  .stammdaten {
    width: calc(100% - 1rem);  // Adjusted to match new mobile margins
  }
  .pruefungen {
    width: calc(100% - 1rem);  // Adjusted to match new mobile margins
  }

  .trainerlizenz {
    width: calc(100% - 1rem);  // Adjusted to match new mobile margins
  }

  .profile {
    flex-direction: column;

    .row {
      flex: 1 1 100%;
      max-width: 100%;
      min-width: 100%;
      min-height: inherit;
    }
  }
}

.even {
  background-color: #f0f0f0;
}

.odd {
  background-color: #ffffff;
}

.rejected {
  background-color: red;
  color: white !important;
  padding: 2px 5px;
  border-radius: 0.2rem;
}

.created {
  background-color: dodgerblue;
  color: white !important;
  padding: 2px 5px;
  border-radius: 0.2rem;
}

.approved {
  background-color: limegreen;
  color: white !important;
  padding: 2px 5px;
  border-radius: 0.2rem;
}

.trainerbutton {
  margin: 1rem 0.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--gray);
  margin-bottom:10rem;
}