// src/components/help/HelpWindow.module.scss
.errorContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 2rem;
  text-align: center;

  p {
    margin-bottom: 1rem;
    color: #dc3545;
  }
}

.retryButton {
  padding: 0.5rem 1rem;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background-color: #0056b3;
  }
}

.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  .spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 1rem;
  }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.helpWindow {
  padding:1rem;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 50%;
  background: #f8f8f8f8;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;

  .closeButton {
    max-width: 10rem;
  }

  .helpContent {

    flex: 1;
    overflow-y: auto; // Add scrollbar to content
    padding-right: 1rem; // Prevent content from touching scrollbar
    .markdownContent {
      // Custom scrollbar styling
      &::-webkit-scrollbar {
        width: 8px;
      }

      &::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 4px;

        &:hover {
          background: #555;
        }
      }
    }


    &.mobile {
      width: 100%;
    }
  }
}

.helpImage {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
  border: 1px solid #eee;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}