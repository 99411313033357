.card {
  padding: 2rem;
  max-width: 1000px;
  margin: 2rem auto;
  margin-bottom: 10rem;
}

.trainer {
  h2 {
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.8rem;
  }

  h3 {
    font-size: 1.3rem;
    text-align: center;
    padding: 2rem 0rem;
    border-top:1px solid var(--gray);
    border-bottom:1px solid var(--gray);
    text-transform: uppercase;
  }

  p {
    line-height: 1.6;
    margin-bottom: 1rem;
    font-size: 1rem;

    &.bullet {
      margin-left: 1.5rem;
      position: relative;

      &:before {
        content: "⟐";
        position: absolute;
        left: -1rem;
      }
    }

    strong {
      font-weight: 600;
    }
  }

  ul {
    list-style: none;
    padding-left: 0;
    margin-bottom: 1.5rem;

    li {
      margin-bottom: 1rem;
      line-height: 1.6;
      position: relative;
      padding-left: 1.5rem;

      &:before {
        content: "\2013";
        position: absolute;
        left: 0;
      }
    }
  }

  .checkbox-container {
    display: flex;
    align-items: center;
    margin: 1.5rem 0;
    padding: 1rem;
    border-radius: 8px;
    transition: all 0.3s ease;

    &:hover {
      background-color: #e9ecef;
      box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    }

    input[type="checkbox"] {
      appearance: none;
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      border-radius: 4px;
      margin-right: 10px;
      position: relative;
      cursor: pointer;
      transition: all 0.2s ease;

      :checked {
        &:after {
          content: "✓";
          position: absolute;
          color: white;
          font-size: 14px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }

      &:disabled {
        cursor: not-allowed;

        &:after {
          content: "✓";
          position: absolute;
          color: white;
          font-size: 20px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }

    label {
      font-size: 1rem;
      cursor: pointer;
      user-select: none;
      strong {
        font-weight: 600;
      }
    }
  }

  // Animation for new content
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  // Apply animation to content that appears after checkbox is checked
  ul, p, h3 {
    animation: fadeIn 0.3s ease-out forwards;
  }

  // Style for the submit button container
  .button-container {
    margin-top: 2rem;
    animation: fadeIn 0.3s ease-out forwards;
  }
}

// Responsive adjustments
@media (max-width: 768px) {
  .card {
    margin: 1rem;
    padding: 1rem;
    margin-bottom: 7rem;
  }

  .trainer {
    h2 {
      font-size: 1.5rem;
      text-align: center;
    }

    h3 {
      font-size: 1.3rem;
      text-align: center;
      padding: 2rem 0rem;
      border-top:1px solid var(--gray);
      border-bottom:1px solid var(--gray);
      text-transform: uppercase;
    }

    p, ul li {
      font-size: 1.1rem;
    }

    .checkbox-container {
      padding: 1rem;

      input[type="checkbox"] {
        width: 1rem;
        height: 1rem;
      }

      label {
        font-size: 1rem;
      }
    }
  }
}