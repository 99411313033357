
.item {
  margin: 1rem;
}

.save {
  margin-top: 4rem;
  width: 100%;
}

.submit{
  margin:1rem 0rem;
}