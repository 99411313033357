.content {
  // position: absolute;
  // max-height: var(--content-height);
  //width: 100vw;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  // pointer-events: none;


  .children {

    transition: 250ms;
    // background-color: white;
    backdrop-filter: blur(0.1rem);
    background-color: rgba(255, 255, 255, 0.8);
    &.loading {
      //opacity: 0;
      transform: translateY(100%);
      pointer-events: none;
    }

    &.active {
      // opacity: 1;

      transform: translateX(0) translateY(0);
      pointer-events: all;
    }
/*
    &.previewRight {
      transform: translateX(100%);
      pointer-events: none;

    }

    &.previewLeft {
      transform: translateX(-100%);
      pointer-events: none;
    }*/

    &.navigate {
      transform: translateX(-100%);
      pointer-events: none;

    }
  }

}