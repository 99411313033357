.thumb {
  // background-color: var(--gray);
  height: 14rem;
  overflow: hidden;
  position: relative;
  background: radial-gradient(circle at 50% 25%, rgba(0,0,0,0) 0, rgba(0,0,0,0.1) 50%,  rgba(0,0,0, 0.5) 100%);
  //margin-bottom: var(--spacing);
  //margin-top: var(--spacing);
  .bar {
    position: absolute;
    z-index: 2;
    bottom: 0;
    left: 0;
    right: 0;
    height: 4rem;
    background-color: rgba(255,255,255,0.7);
    backdrop-filter: blur(0.1rem);
    padding: var(--spacing) var(--spacing);
    h2 {
      font-size: 1.1rem;
      font-weight: bolder;
      margin-top: 0;
      margin-bottom: 0;
    }
    p {
      margin: 0;
    }

    .date {
      color: rgba(0,0,0,0.5);
      font-weight: normal;
      position: absolute;
      right: var(--spacing);
      bottom: var(--spacing);
    }
  }



  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    // transform: translateX(100%);
    transform: scale(1.05);
    opacity: 0;
    transition: 0.25s ease-in;
    filter: blur(1rem);
    mix-blend-mode: multiply;
  }

  &.active {
    img {
      // transform: translateX(0);
      opacity: 1;
      transform: scale(1);
      filter: blur(0);
    }
  }
}