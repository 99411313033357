
.active {
  background-color: var(--color-success-light);




}


.entry {
  display: flex;
  flex-direction: row;

  .date {
    width: 7rem;
    color: var(--gray);
  }

  .name {
    border-left: 2px dotted var(--gray-light);
    padding-left: 1rem;
    flex: 2;
    font-weight: bold;
  }
}