.container {
  display: flex;
  position: relative;
  align-items: center;
  width: 100vw;
  height: 3rem;
  background-color: #8c8c8c;
  z-index: 1;
  transition: .3s;

  &Block {
    display: flex;
    justify-content: center;
    width: 50%;
    &.active {
      font-weight: 600;
      color: white;
    }
    z-index: 2;
  }

  &Activity {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 50%;
    height: 3rem;
    background-color: rgba(184, 32, 37, 0.4);

    pointer-events: none;
    transition: .3s;
    &.active {
      transform: translateX(100%);
    }

  }

}

.text {
  font-weight: bolder;
}

