.beltStripes {
  // Add any additional styling here
  &:hover {
    border-color: #000;
  }
}
.container {
  display: flex;
  overflow: hidden;
}
