.container {
  display: flex;
  align-items: center;
  gap: 1rem; // This adds space between the button and avatar
  justify-content: flex-end;

  .logoutbttn {
    font-size: 1rem;
    font-weight: bold;
  }

  .menu {
    font-size: 1.5rem;
    //padding: 0.25rem 1rem;
    color: white;
    //border: 3px solid lime;
    //border-radius: 0.3rem;
    cursor: pointer;
    transition: linear 150ms ;

    &:hover, &.open {
      color: var(--primary-color);
      //background-color: white;
    }
    &.open {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    $imageWidth: 3rem;
    $borderWidth: 4px;// 4px is the border width from image

    .imageContainer {
      width: calc(#{$imageWidth} + #{$borderWidth});
      height: calc(#{$imageWidth} + #{$borderWidth});
      //border:3px solid white;
      //border-radius: 1rem;
      //background-color: white;

      .image {
        display: block;
        width: $imageWidth;
        height: $imageWidth;
        object-fit: cover;
        border-radius: 0.5rem;
        border:2px solid white;
        opacity: 0.8;
        transition: opacity 0.3s ease;
        &:hover {
          border:2px solid white;
          opacity: 1;

        }
      }
    }
  }

  .content {
    position: absolute;

    z-index: 1000;
    min-width: 5rem;
    background-color: white;
    padding: 0.5rem 1rem;
    border: 1px solid var(--gray-light);
    border-radius: 0 0.5rem 0.5rem 0.5rem;
    box-shadow: var(--shadow);
  }


}

.judocard{
  //width: 5rem;
  height: 2.6rem;
  //border:2px solid white;
  //border-radius: 0.5rem;
  //background-color: white;
  &.judocardactive {
    color: lawngreen;
    //&:hover, &.open{
    //  color: lightgreen;
    //}
  }
  &.judocardinactive {
    color: white;
    //&:hover, &.open{
    //  color: var(--gray-light);
    //}
  }
}