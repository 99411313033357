.header {
  display: flex;
  flex-direction: column;
}

.content {
  //height: var(--content-height);
  height: var(--content-height);

  background-color: var(--text-bg);
  //height: 100vh;
  /*display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;*/
  width: 100%;
  display: flex;
  flex-direction: column;

  .mainImg {
    width: 100vw;
  }

  .img {
    flex: 1;
    border: 2px solid var(--text-bg);
    opacity: 0.6;
    transition: 150ms linear;
    &.active {
      border: 2px solid var(--primary-color);
      opacity: 1;
    }
  }

  .list {
    /*display: flex;
    flex-direction: row;
    overflow: scroll;*/
    //margin-top: auto;
    //height: rem;


    img {
      flex: 1;
      // width: calc(100% - calc(2 * var(--spacing)));
      //margin: calc( 0.5 * var(--spacing));
      height: 3rem;
      width: 100%;
      object-fit: cover;

    }

  }

  .preview {
    height: 100%;
    width: 100%;
    overflow: hidden;
//    flex: 1;
    //animation: fadeInAnimation 500ms ease-in;

  }



}

.container {
  //margin-top: env(safe-area-inset-top);
  //margin-bottom: env(safe-area-inset-bottom);
  //margin-top: 2rem;
  // margin-bottom: 2rem;
}

@keyframes fadeInAnimation {
  0% { opacity: 0; }
  100% { opacity: 1; }
}