@import "~leaflet/dist/leaflet";

body {
  font-family: var(--font-family);
  font-weight: 500;
  font-style: normal;
}

a, a:hover, a:active, a:visited {
  color: var(--primary-color)
}


/**********************************************************************************************************************/
/* React Datepicker                                                                                                   */
/**********************************************************************************************************************/
.react-datepicker__input-container input {
  width: calc(100% - 1.5rem);
  margin: calc(0.25 * var(--spacing));
  padding: var(--spacing);
  border: 1px solid var(--gray);
  background-color: var(--primary-color-super-light);
  font-size: 1rem;
  border-radius: var(--border-radius);
  &:disabled {
    background-color: var(--gray-super-light);
  }
}


.react-datepicker-wrapper, .react-datepicker__input-container {
  display: block;
  width: 100%;
}

.react-datepicker-popper {
  //left: auto !important;
  //right: 0.5rem !important;
  //transform: none !important;
}

.react-datepicker__month-container {
  width: 100% !important;
  font-size: 1rem;
}


.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  // padding: 1rem;
  // padding-left: 0;
  //padding-right: 0;
  width: 100px;
  overflow-y: auto;
}

.react-datepicker {
  font-size: 2rem;

}

.react-datepicker__header {
  background-color: var(--gray-super-light);
  color: var(--text-fg);
}

.react-datepicker__current-month {
  color: var(--text-fg);
}

.react-datepicker__day-name,
.react-datepicker__day {
  padding: 0.4rem;
}

.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
  //color: var(--primary-color);
}


.react-datepicker__day:hover, .react-datepicker__month-text:hover, .react-datepicker__quarter-text:hover {
  background-color: var(--primary-color) !important;
}

.react-datepicker__day--today {
  font-weight: bold;
  border: 1px solid var(--primary-color);
  border-radius: 0.25rem;
  // color: var(--primary-color) !important;
}

.react-datepicker__day--selected, .react-datepicker__day--selected:hover{
  background-color: var(--primary-color) !important;
  color: white !important;
}

.react-datepicker__navigation-icon {
  color: var(--text-fg);
}

.react-datepicker__year-dropdown {
  background-color: var(--text-bg);
  padding: 0.5rem;
}

.react-datepicker__year-option {
  min-height: 1rem;
  background-color: transparent;
  &:active,&:focus,&:hover {
    background-color: transparent;
  }
}
.react-datepicker__header__dropdown {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.react-datepicker__navigation--years-upcoming {
  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;

  border-bottom: 1rem solid var(--primary-color);
  //margin-bottom: 0.5rem;
  &:active,&:focus,&:hover {
    border-bottom-color: var(--primary-color) !important;
  }
}

.react-datepicker__navigation--years-previous {

  width: 0;
  height: 0;
  border-left: 1rem solid transparent;
  border-right: 1rem solid transparent;

  border-top: 1rem solid var(--primary-color);
  //margin-bottom: 0.5rem;
  &:active,&:focus,&:hover {
    border-bottom-color: var(--primary-color) !important;
  }
}

/// TOASTER


.Toastify__toast-container {
  margin-top: calc(var(--top-inset) + 4.5rem);
}
