.datepicker-container {
  :global {
    .react-datepicker {
      font-family: inherit;
    }

    .react-datepicker__today-button {
      background: #3880ff;
      color: white;
      border: none;
      padding: 0.5rem;
      cursor: pointer;
      text-align: center;
      font-weight: bold;

      &:hover {
        background: #3171e0;
      }

      &:disabled {
        background: #cccccc;
        cursor: not-allowed;
      }
    }
  }
}