
//.image {
//  //background-image: var(--logo);
//  background-position: center;
//  background-size: 50vw;
//  background-repeat: no-repeat;
//  width: 100%;
//  height: 20vh;
//}

.card, .item {
  border: 1px solid #92949f;
  margin: 1rem 1rem 5rem;
  border-radius: 0.5rem;
  background-color: #E0E8F6;
  -webkit-margin-after: 15rem;

  form {
    h3{
      margin: 0.5rem 0;
    }
    > div {
      display: flex;
      flex-direction: column;
      margin-bottom: 0.5rem;

      label {
        margin-bottom: 0rem;
        font-size: 0.9rem;
        color: #666;
        min-width: initial;  // Remove the fixed width
        max-width: initial;  // Remove the fixed width
      }

      input[type=email], input[type=text], input[type=number] {
        margin-left: 0;  // Remove left margin since label is above
        border-radius: 0.3rem !important;
        background-color: #ffffff;
        padding: 0.5rem;
        border: 2px solid var(--gray);
        width: 100%;     // Use full width
        max-width: 100%; // Allow input to fill container
        box-sizing: border-box;
      }

      input[type=number] {
        font-size: 1.3rem;
        max-width: 6rem;
      }
    }
  }
  .info {
    //margin: 1rem 1rem;
    //padding: 1rem 1rem;
    //color: black;
    font-weight: normal !important;
    line-height: 1.8rem;
    font-size: 1.1rem;
  }
}

.pin-login {
    &Title {
      text-align: center !important;
      color: var(--gray-dark);
    }
}

.loginTypeButtons {
  display: flex !important;
  flex-direction: row !important;
  justify-content: space-between !important;
  margin: 0.5rem 0rem;
  gap: 1rem;

  > div {
    width: 45% !important;
    padding: 0.5rem 1rem;
    text-align: center;
    border: 1px solid #92949f;
    border-radius: 0.5rem;
    background-color: #E0E8F6;
    cursor: pointer;
  }
}

input[type=password] {
  margin: 1rem 0;  // Remove left margin since label is above
  border-radius: 0.3rem;
  background-color: white !important;
  padding: 0.5rem;
  border: 2px solid gray;
  width: 100%;     // Use full width
  max-width: 100%; // Allow input to fill container
  box-sizing: border-box;
  font-size: 1.2rem;
  letter-spacing:0.2rem;
  font-style: italic;
}

.loginTypeButtons {

  display: flex;
  gap: 0.5rem;
  margin: 1rem;
}

.pinbutton{
  font-size: 1.2rem;
  padding:0rem 1rem !important;
  width: 100%;
  background-color: #367bf9 !important;
  padding: 0.5rem;
  margin:1rem 0rem 1rem;
}
.pinSection {
  margin: 1rem 0;

  label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
  }
}

.pinInputContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 0.1rem;
  width: 100%;  // Keep full width
  justify-content: space-between;  // Changed from center to space-between
  padding: 0rem;  // Add padding to match email input padding
  //border:1px solid red;
}

.version {
  display: flex;
  margin:0 auto;
  padding-top: 0.51rem;
  font-size: 2.2vh;
  font-weight: normal;
  color: var(--gray-dark);
  justify-content: center;
  align-items: center;
  min-width: 12rem;
  //border: 1px solid var(--gray);
}
