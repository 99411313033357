.layout {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: var(--max-content-width);
  padding: 0 var(--content-padding);
  margin: 0 auto;

  .list {
    background-color: rgba(255,255,255,0.75);
    backdrop-filter: blur(0.25rem);
    max-width: 30rem;
    flex: 1;
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
  }

  .page {
    flex: 2;
    background-color: rgba(255,255,255,0.5);
    backdrop-filter: blur(.25rem);
    overflow-y: scroll;
    overflow-x: hidden;
    height: calc(100vh - 4rem);
    max-width: var(--max-content-width);
  }
  @media (max-width: 91rem) { // 1456px - slightly larger than max-width
    margin: 0 1rem;
  }
}