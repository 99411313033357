
.judges {
  padding: 0.5rem;
  width: 100%;

  .header {
    padding: 0.5rem;
    font-weight: bold;
    display: flex;
    .text {
      flex: 1;
      display:block;
      overflow: hidden;
    }
    .judoCard {

      background-color: var(--color-success);
      color: var(--text-bg);
      padding: 0.25rem;
      margin: -0.25rem 0.25rem 0;
    }
  }

  .content {
    padding: 0.5rem;
    font-weight: normal;
    color: var(--text-fg);
  }

  .age {
    font-weight: bold;
    color: var(--text-fg)
  }

}

.save {
  width: 100%;
}

.select {
  display: flex;
  min-width: 14rem;

  .dropdown {
    flex: 1;
  }

  .clear {

    margin-left:0.5rem;
    font-size: 1.2rem;
  }
}

