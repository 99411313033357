

.image {
    height: 5rem;
    width: 5rem;
    object-fit: cover;
    //transform: scale(1.05);
    opacity: 0;
    transition: 0.25s ease-in;
    filter: blur(0.5rem);
    //mix-blend-mode: multiply;

  overflow: hidden;
  &.active {

      opacity: 1;
      transform: scale(1);
      filter: blur(0);

  }

}
