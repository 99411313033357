
.leftmenue {
  margin-right:0.2rem;
  padding-right: 0.5rem;
  margin-bottom: 10rem;
  .entry {
    display: flex;
    flex-direction: row;
    &.selected {  // Changed from :global(.selected)
      //background-color: var(--primary-color-light);
      //border-left: 0.5rem solid var(--primary-color);
      padding-right: 0.5rem;
      background-color: rgba(var(--dodgerblue-rgb),0.3) !important;
      .date {
        color: var(--text-fg);
      }
      .name {
        //font-weight: bold;
        .status {
          //background-color: rgba(var(--primary-color-rgb), 0.1);
          //color:red;
        }
      }
    }

    .date {
      width: 12rem;
      color: var(--text-fg);
      min-width: 15rem;
      .creator {
        font-size: 0.8rem;
        color: var(--gray-dark);
      }
      .org {
        margin-top: 0.25rem;
        font-weight: bold;
        font-size: 0.9rem;
        color: var(--text-fg);

      }

      .nr {
        color: #ff7b00;
        font-size: 0.8rem;
      }
    }

    .name {
      border-left: 2px dotted var(--gray-light);
      padding-left: .5rem;
      flex: 2;
      //font-weight: bold;
      display: flex;

      .judocardGroup {
        min-width: 4rem;
        .judocard {
          font-size: 0.85rem;
          color: white;
          background-color: var(--gray-dark);;
          border: 1px solid var(--gray);
          margin: 0 0.1rem 0.1rem;
          padding: 0.25rem;
          border-radius: 0.25rem;
          text-align: center;
          font-weight: bold;
        }
        .amount {
          margin: 0 0.1rem 0.1rem;
          padding: 0.25rem 0.5rem;
          text-align: center;
          font-size: 0.8rem !important;
          color: var(--gray);
          font-weight: bold;
        }
      }

      .info {
        flex: 1;
        //margin: 0 0.5rem;

        .kyupruefung {
          text-align: left;
          margin-top: 0.3rem;
          color: var(--dodgerblue);
          font-size: 0.85rem;
        }
      }
    }
  }

    .submitted, .submitted-by-kyu {
      background-color: #ffe2d3 !important;
      color: var(--primary-color) !important;
      &:global(.selected) {
        background-color: var(--color-warning-darker) !important;
        border-left: 3px solid var(--color-warning-dark);
      }
      &:hover {
        filter: brightness(0.9);
      }
    }

    .approved {
      //background-color: #93ff93 !important;
      &:global(.selected) {
        background-color: var(--color-warning-darker) !important;
        border-left: 3px solid var(--color-warning-dark);
      }
      &:hover {
        filter: brightness(0.9);
      }
    }

    .created {
      background-color: #f1f1f1;
      &:global(.selected) {
        background-color: var(--color-warning-darker) !important;
        border-left: 3px solid var(--color-warning-dark);
      }
      &:hover {
        filter: brightness(0.9);
      }

  }

}


.headerempty {
  text-align: center;
  margin:1rem 3rem;
  p{
    font-size: 1.5rem;
  }
}

.dateFilter {
  background-color: var(--background-color);
  padding: 1rem;
  border-radius: 0.25rem;
  //border: 1px solid var(--gray-light);
}

.dateInputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
}

.dateInput {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  label {
    font-weight: bold;
    color: var(--gray-dark);
  }

  .input {
    width: 100%;
  }
}

.excelTable {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 2rem;

  th {
    position: sticky;
    top: 0;
    background: var(--background-color);
    z-index: 10;
    padding: 0.5rem;
    border: 2px solid white;
    white-space: nowrap;
    background-color: rgba(var(--primary-color-rgb), 1  );
    color: white;
  }

  .filterRow {
    position: sticky;
    top: 2.5rem; // Adjust based on your header height
    z-index: 9;
    background-color: white;

    td {
      padding: 0.5rem;
      border: 2px solid white;
      background-color: rgba(var(--primary-color-rgb), 0.1);

      input {
        width: calc(100% - 6px);
        padding: 0.25rem;
        border: 1px solid var(--gray);
        border-radius: 3px;
        font-size: 0.9rem;
        margin: 0 3px;
      }
    }
  }

  td {
    background-color: rgba(var(--primary-color-rgb), 0.1);
    border: 2px solid white;
    padding: 0.25rem;
    text-align: center;

    &:nth-child(7) {
      text-align: left;
    }
  }
}

// Add print-specific styles
@media print {
  .excelTable {
    th,
    .filterRow td {
      position: relative !important;
      top: auto !important;
      background-color: rgba(var(--primary-color-rgb), 1) !important;
      -webkit-print-color-adjust: exact !important;
      print-color-adjust: exact !important;
    }
  }
}

.sortableHeader {
  user-select: none;
  position: relative;
  padding-right: 1.5rem;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.6);
  }

  .sortIcon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}


.search {
  display: flex !important;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  margin: 1rem;
}

.searchInput {
  width: calc(100% - calc(4 * var(--spacing)));
  padding: 4px 8px;
  border: 1px solid var(--gray);
  border-radius: 0.25rem;
  font-size: 1em;
  //margin: 0px;

  &:focus {
    outline: none;
    border-color: var(--primary-color);
    box-shadow: 0 0 0 1px var(--primary-color);
  }

  &::placeholder {
    color: var(--gray-dark);
    opacity: 0.7;
  }
}

.excelTable {
  th, td {
    padding: 8px;
    white-space: nowrap;
  }
  th:nth-child(7) {
    width: 25%;
  }

  thead {
    tr:nth-child(2) td {  // Search row
      padding: 4px 8px;
      background-color: var(--gray-lighter);
    }
  }
}

.add {
    background-color: lightseagreen;
    transition: filter 0.2s ease;
    color:white;
  &:hover {
    filter: brightness(0.9);
  }
}

.sortableHeader {
  user-select: none;
  position: relative;
  padding-right: 1.5rem;

  &:hover {
    background-color: rgba(var(--primary-color-rgb), 0.6);
  }

  .sortIcon {
    position: absolute;
    right: 0.5rem;
    top: 50%;
    transform: translateY(-50%);
  }
}

.greenIcon {
  color: #00a800 !important;
  &:global(.fa-icon) {
    color: #00a800 !important;
  }
}

.draftIcon {
  color: gray !important;
  &:global(.fa-icon) {
    color: gray !important;
  }
}

.submittedIcon {
  color: red !important;
  &:global(.fa-icon) {
    color: red !important;
    background-color: white;
  }
}