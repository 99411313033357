$padding: 0.25rem;
$vh: calc(100vh - calc(calc(2 * var(--frame-height)) + calc(env(safe-area-inset-top) + env(safe-area-inset-top))));
$color_judocard: #0063c7;
$color_highlight: #808285;
$color_background: #fff;
$color_text: black;

.judo {
  width: 100%;
  height: $vh;
  // background-color: $color_highlight;
  //padding: $padding;

  &Card {
    position: absolute;
    transform: rotate(90deg);
    width: calc(#{$vh} - calc(8 * var(--spacing)));
    height: calc(100vw - calc(8 * var(--spacing)));
    // margin-left: calc(100% - #{$padding * 2});
    transform-origin: left top;
    background-color: $color_background;
    padding: 1rem;
    margin-left: calc(100vw - calc(var(--spacing) * 2));
    margin-top: calc(2 * var(--spacing));
    border: 2pt solid var(--gray-light);
    box-shadow: 0 0 0.5rem 0.5rem var(--gray-super-light);
    border-radius: 2rem;


    .container {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-content: center;
      height: 100%;
      padding-right: 1rem;
      width: calc(100% - calc(min(37.5vw, 25vh))); //calc( 100% - calc(0.4 * #{$vh}));

      div {
        flex:1;
        // border: 1px solid red;
      }
    }


    .name {
      font-size: calc(0.035 * #{$vh});
    }
    h1 {
      margin-top: 0;
    }

    .title {
      label {
        color:grey;
        text-transform: uppercase;
        font-size:0.7rem;
      }
      flex: 2;
      // display: flex;
      // justify-content: space-between;
      h1 {
        margin-top: calc(0.005 * #{$vh});
        margin-bottom: 0.5rem;
        color: #ccc;
        font-size: calc(0.045 * #{$vh}); //  4rem;
        font-weight: bold;
        display: inline-block;
        color: $color_judocard;
        span {
          font-size: 1.2rem;
          //color: #cb0a0a;

          color:red !important;
          text-transform: uppercase;
          font-size: 0.80rem;

        }

      }
      .logo {
        position: absolute;
        // float: right;
        right: 1rem;
        top: 1rem;
        display: inline-block;
        height: calc(0.11 * #{$vh});
        width:calc(0.23 * #{$vh});
        margin-top: calc(-0.01 * #{$vh});
        margin-bottom: calc(0.05 * #{$vh});
        margin-left: 3rem;
        background-image: url("../../../theme/editions/archeryaustria/images/logo.svg");
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        //border:1px solid red;
      }

    }

    label {
      margin-top: 0.1rem;
      font-size: calc(0.03 * #{$vh}); //  1.5rem;
      display: block;
    }

    .grades {
      .grade {
        display: inline-block;
        width: 3rem;
        height: 2rem;
        border: 1pt solid $color_highlight;
      }

      .maxgrade {
        margin-left: 1rem;
        vertical-align: top;
        line-height: 2rem;
      }
    }

    .beltContainer{
      display: inline-block;
      label {
        margin-top: 0rem;
        height:2.8vh;
      }

      .belt {
        margin-left:0.5rem;
        margin-top: 0.1rem;
      }

      .belt svg  {
        height: 2.4vh;
        width:auto;
        border:1px solid black;

      }
    }

    .allGrades {
      display: flex;
      .rwr {
        // width: fit-content;
        background-image: url("../../../theme/editions/archeryaustria/images/rwr-pfeil.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 5rem;
        max-width: 5rem;
        min-width: 4rem;
        height: 3rem;
        margin-top: 0.1rem;
        margin-left: 0.1rem;
        //border:1px solid red;
      }
      .rwrPlaceholder {
        //background-image: url("../../theme/editions/archeryaustria/images/rwr-pfeil_gray.png");
        background-size: contain;
        background-repeat: no-repeat;
        width: 5rem;
        max-width: 5rem;
        min-width: 4rem;
        height: 3rem;
        margin-top: 0.1rem;
        margin-left: 0.1rem;
        //border:1px solid red;
      }
      label{
        //border:1px solid red;
        color: #0097ff;
        font-weight: bold;
        font-size: 1rem;
      }
    }

    .memberships {
      label {
        color:grey;
        text-transform: uppercase;
        font-size:0.7rem;
      }
      sup {
        font-weight: bold;
        color:$color_judocard;
        //&:after {
        //contect: url("../../theme/editions/archeryaustria/images/rwr-pfeil_gray.png");
        //}
      }
      div {
        color:black;
        //font-size: 1.5rem;
      }
      margin-bottom: 1rem;
    }
    .birthdate {
      margin-top: 0.1rem;
      margin-bottom: 0.1rem;
      .age-class {
        //padding-left:2rem;
      }
      .gender {
        //line-height: 1.5rem;
        font-size: 1.5rem;
        font-weight: 900;
      }

      .female {
        color: deeppink;
      }

      .male {
        color: #0077f6;
      }

      .genderx {
        color: #209000;
      }
    }
    .profile {
      position: absolute;
      right: 1rem;
      bottom: 1rem;
      width: calc(min(37.5vw, 25vh));
      height: calc(min(60vw, 40vh));
      border-radius: 1rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
    }
  }

  .sponsor {
    // width: calc(.25 * #{$vh});
    height: calc(0.5 * #{$vh});
    max-width: 20rem;
    background-position: left;
    background-repeat: no-repeat;
    background-size: contain;
    background-image: url("../../../theme/editions/archeryaustria/images/sponsoren.png");
  }
}
