.center {

  max-width: 25rem;
  margin: auto;
  .image {
    background-image: var(--logo);
    // "../../theme/images/logo.png"
    background-position: center;
    background-size: 25rem;
    background-repeat: no-repeat;
    width: 100%;
    height: 25vh;
  }

  .info {
    padding-top: 1rem;
    color: gray;
    font-weight: normal !important;
    line-height: 1.5rem;
  }

  .card {
    border: 1px solid white;
  }

  .pin-login {
    &Title {
      text-align: center !important;
      color: #666;
    }
  }

}

.forbidden {
  margin-top:10rem;
}

.help {
  padding: 2rem 1rem 0rem 1rem;
  max-width: 15rem;
  margin:0 auto;


  .helplink {
    margin-top: 1rem;
    //border:1px solid var(--gray-dark) !important;
  }

  .version {
    display: flex;
    margin:0 auto;
    padding-top: 1rem;
    font-size: 1rem;
    font-weight: normal;
    color: black;
    justify-content: center;
    align-items: center;
    min-width: 12rem;
    //border: 1px solid var(--gray);
  }
}

.oldjama  {
  margin:0rem auto;
  text-align: center;
  p {
    color:black;
  }
}

a {
  text-decoration: none;
  :hover {
    color:black;
  }
}
.submitbttn{
  margin:1rem ;
}
.passwdreset {
  margin:1rem auto;
}

.jamaheader {
  margin:1.5rem auto;
  font-weight: bold;
  color:black;
  font-size:1.5rem;
  text-align: center;
  width:100%;
}
