$padding: var(--spacing);

$imageWidth: 9rem;

.background {
  // width: 100%;
  // min-height: 100%;
  //background-color: var(--gray);
  //padding: $padding $padding 5rem;
  padding: var(--spacing);
  margin-bottom: 0.5rem;
  // margin-bottom: 8rem;

  &.show-filter {
    padding-bottom: 25rem;
  }

}

.filter-judocards {
  ion-label {
    min-width: 30%;
  }
}
