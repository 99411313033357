.select {
  :global {
    .Select__control {
      width: 100%;
      height: auto;
      min-height: 3rem;
      border: 1px solid var(--gray);
      background-color: var(--primary-color-super-light);

      &--is-disabled {
        background-color: var(--gray-super-light);
      }
    }

    .Select__option {
      white-space: pre-wrap;
      //padding: 0.5rem 1rem;
      font-size: 0.9rem;
      line-height: 1.5;

      &--is-focused {
        background-color: var(--primary-button-color-light);
        color: white;
      }
    }

    .Select__menu {
      width: 100%;
      margin: 0;
    }

    .Select__single-value {
      white-space: pre-wrap;
      line-height: 1.5;
    }

    .Select__placeholder {
      color: var(--gray);
    }
  }
}

.notification-form {
  &__event-line {
    // Styles for the line container

    &--main {
      font-weight: bold;
    }

    &--details {
      font-size: 1em;
      color: var(--gray-dark);
    }
  }
}

.notification-form-select {
  :global {
    // Option styling
    .Select__option {
      border-bottom: 1px solid var(--gray-light) !important;
      padding: 12px 16px;  // Increased padding for more height
      min-height: 70px;    // Set minimum height

      // Selected state
      &--is-selected {
        background-color: var(--primary-color-super-light) !important;

        .notification-form__event-line {
          &--main {
            color: white;
          }

          &--details {
            color: white !important;  // Force white color in selected state
          }
        }
      }

      // Hover/Focus state
      &--is-focused {
        background-color: var(--primary -button-color-light);

        .notification-form__event-line {
          &--main {
            color: white;
          }

          &--details {
            color: white !important;
          }
        }
      }

      // Last option shouldn't have a border
      &:last-child {
        border-bottom: none;
      }
    }

    // Make the dropdown menu taller
    .Select__menu {
      .Select__menu-list {
        max-height: 30rem;  // Increase max height of dropdown
      }
    }
  }
}

.editor {
  :global {
    .ql-container {
      min-height: 200px;
      font-size: 16px;
    }

    .ql-editor {
      min-height: 200px;
    }

    .ql-toolbar {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .ql-container {
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.characterCount {
  font-size: 0.8em;
  color: var(--gray-dark);
  text-align: right;
  margin-top: 4px;

  &--warning {
    color: var(--warning-color);
  }

  &--error {
    color: var(--error-color);
  }

  &--green {
    color: var(--green-color);
  }
}

.checkbox {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;

  input[type="checkbox"] {
    width: 16px;
    height: 16px;
  }

  label {
    font-size: 14px;
    cursor: pointer;

  }
}

.checkboxGroup {
  margin-bottom: 8px;
}

.selectAllContainer {
  margin-bottom: 4px;

  &:last-child {
    margin-bottom: 8px;
  }
}

.selectAllLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  //
  //&:has(input:disabled) {
  //  cursor: not-allowed;
  //  opacity: 0.7;
  //}
}

.selectAllCheckbox {
  margin-right: 8px;

  &:disabled {
    cursor: not-allowed;
  }
}

form {
  label {
    line-height: 2rem;
  }
}