.background {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(255,255,255,0.8);
  backdrop-filter: blur(0.25rem);
  z-index: 100000;
  animation: fadeIn 125ms ease-in;
}

.ring {
  position: fixed;
  left: calc(50% - 2rem);
  top: calc(50vh - 2rem);
  width: 4rem;
  height: 4rem;
  transform: translateX(-20%); // Move 20% to the left
  z-index: 100001; // Ensure it's above the background

  & div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 64px;
    height: 64px;
    margin: 8px;
    border: 8px solid #ccc;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: var(--primary-color) transparent transparent transparent;
  }

  & div:nth-child(1) { animation-delay: -0.45s; }
  & div:nth-child(2) { animation-delay: -0.3s; }
  & div:nth-child(3) { animation-delay: -0.15s; }
}

@keyframes lds-ring {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingOverlay > div {
  margin-top: 10px;
  font-weight: bold;
}