
.input {
  font-size: 1rem;

  width: calc(100% - calc(3 * var(--spacing)));
  margin: calc(0.25 * var(--spacing));
  padding:  var(--spacing);
  border-radius: var(--border-radius);
  border: 1px solid var(--gray);
  background-color: var(--primary-color-super-light);
  transition: linear 150ms;

  &:active, &:focus {
    outline: none;
    border-color: var(--primary-color);
    background-color: white;
  }
  &:disabled {
    color: var(--gray);
    background-color: var(--gray-super-light);
  }
}