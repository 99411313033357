
.navItem {
  margin: 0;
  padding: 0;
  &:last-child {
    margin-bottom: 8rem;
  }
  &.itemInactive {
    opacity: 0.5;
  }
  .item {
    display: flex;
    flex-direction: row;
    //border-left:3px solid lawngreen;

    .avatar {
      width: 4rem;
      height: 4rem;
      object-fit: cover;
      image {
        width: 4rem;
        height: 4rem;
      }
    }

    .text {
      margin-left: 1rem;
      display: flex;
      flex: 1;
      flex-direction: column;

      .nameLicContainer {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        height: 1rem;

        .name, .license {
          //white-space: nowrap; // Prevents content within these divs from wrapping
          //overflow: hidden;    // Hides any overflowed content
          text-overflow: ellipsis; // Adds ellipsis (...) to any text that overflows
        }
        .name {
          text-align: left;
          &.disabled {
              color: var(--gray);
          }
        }
        .license {
          text-align: right;
          color: white;
          padding: 1px 2px;
          width: 3.7rem;
          height:1.2rem;
          border-radius: 3px;


          &.active {
            background-color: #07bc0c;
          }
          &.inactive {
            background-color: var(--gray);
          }
          &.expired {
            background-color: #e32121;
          }
        }
      }

      .bottom {
        display: flex;
        flex-direction: row;
        margin-top: 1.5rem;
        font-weight: normal;

        .birthdate {
          flex: 1;
          color: black;
          text-align: right;
        }
        .org {
          flex: 1;
          color: black;
        }
      }
    }
  }
  .info {

    .state {
      padding: 0.125rem 0.5rem;
      float: right;
      border-radius: 0.25rem;
      margin-top: 0.25rem;
      &.created {

        background-color: var(--color-info)
      }
      &.approved_jv {
        background-color: var(--color-success-light)
      }
      &.approved_lv {
        color: var(--text-bg);
        background-color: var(--color-success)
      }
      &.approved_oejv {
        color: var(--text-bg);
        background-color: var(--primary-color)
      }
      &.canceled {
        color: var(--text-bg);
        background-color: var(--gray);
      }
    }
  }
  .disabled {
    opacity: 0.6;

  }
}

.saveButton {
  margin: 1rem 0.5rem 3rem 0.5rem !important;
  max-width: calc(100% - 1rem);
  &:hover {
    background-color: var(--primary-color-light);
  }
}

.clubHover {
  &:hover {
    background-color: var(--gray-light);
  }
}


.itemnavgroup{
  margin-bottom: 10rem;
}

.barcode {
  width: 100%;
  margin-top: 0.5rem;
  svg {
    width:auto;
    //height: 42px;
  }
}