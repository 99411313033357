
.item {
  display: flex;
  width: 100%;
  //overflow: hidden;
  overflow-wrap: break-word;

  &.selected {
    background-color: var(--primary-color-super-light);
  }

  .padding {
    padding: 0.3rem;
  }

  .spacer {
    width: 100%;
    height: 0.5rem;
  }

  &.list {
    width: 100% ;
    border-bottom: 1px solid var(--gray-light);
    &:active, &:hover, &::selection {
      box-shadow: inset 0 0 0.5rem 0.25rem var(--gray-super-light);
    }
  }

  &.split {
    width: 100%;
    display: flex;
    flex-direction: column;
    .children {
      float: right;
    }
  }

  &.form {
  }

  label {
    margin-top: auto;
    margin-bottom: auto;
    color: var(--gray-dark);
    flex: 1;
  }

  &.full {
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid var(--gray-light);

    label {
      margin-bottom: 0.5rem;
    }

    &:active, &:hover, &::selection {
      box-shadow: inset 0 0 0.5rem 0.25rem var(--gray-super-light);
    }
  }

  & .children {
    flex: 1;
  }

  &.s1 {
    flex: 1;
    min-width: 8.3333%;
    max-width: 8.3333%;
  }
  &.s2 {
    flex: 1;
    min-width: 16.6666%;
    max-width: 16.6666%;
  }
  &.s3 {
    flex: 1;
    max-width: 25%;
    min-width: 25%;
  }
  &.s4 {
    flex: 1;
    min-width: 33.3333%;
    max-width: 33.3333%;
  }
  &.s5 {
    flex: 1;
    min-width: 41.6666%;
    max-width: 41.6666%;
  }
  &.s6 {
    flex: 1;
    max-width: 50%;
    min-width: 50%;
  }
  &.s7 {
    flex: 1;
    min-width: 58.3333%;
    max-width: 58.3333%;
  }
  &.s8 {
    flex: 1;
    min-width: 66.6666%;
    max-width: 66.6666%;
  }
  &.s9 {
    flex: 1;
    max-width: 75%;
    min-width: 75%;
  }
  &.s10 {
    flex: 1;
    min-width: 83.3333%;
    max-width: 83.3333%;
  }
  &.s11 {
    flex: 1;
    min-width: 91.6666%;
    max-width: 91.6666%;
  }
  &.s12 {
    flex: 1;
    max-width: 100%;
    min-width: 100%;
  }

  &.clickable {
    cursor: pointer;
  }
  &.notAllowed {
    cursor: not-allowed;
  }

  @media (max-width: 768px) {
    &.s1, &.s2, &.s3, &.s4, &.s5, &.s6,
    &.s7, &.s8, &.s9, &.s10, &.s11, &.s12 {
      min-width: 100% !important;
      max-width: 100% !important;
    }
  }

}